export const EN_TRANSLATIONS = {
	homePage: {
		welcomeTitle:
			"Welcome to Unibail Rodamco Westfield's work preparation website!",
		welcomeTitle1: 'Welcome to',
		welcomeTitle2: "Unibail Rodamco Westfield's",
		welcomeTitle3: 'work preparation website!',
		fillOutFireAlarmShutdownBtnText:
			'Fill out Fire alarm shutdown & Hot work',
		fillOutWorkPrepBtnText: 'Fill out work preparation',
		fillOutServiceWorkBtnText: 'Fill out service work',
		adminPanelBtnText: 'Admin panel',
		createUserBtnText: 'Create user',
		loginBtnText: 'Sign in',
		logoutBtnText: 'Sign out',
	},
	createUser: {
		title: 'Create user',
		form: {
			name: 'Name',
			email: 'E-mail',
			password: 'Password',
			minPasswordLength: 'The password must be at least six characters',
			confirmPassword: 'Confirm password',
			chooseARole: 'Choose a role',
			role: 'Role',
			submitBtnText: 'Create user',
		},
		creatingUser: 'Creating user...',
		userCreated:
			'User created! An email has been sent to the specified email.',
		error: 'Something went wrong...',
		passwordInfo:
			'By clicking the "Create user" button, an email with instructions to create a password will be sent to the email associated with the user.',
	},
	fireAlarmShutdown: {
		idTitle:
			'Welcome to the application for Fire alarm shutdown & Hot work',
		idInfoText:
			'In order to be able to apply for these jobs, there needs to be an approved work preparation which then generates an ID number which is entered as shown below.',
		fillWorkPrep: 'Here you fill in a work preparation',
		findId: 'This is how you find the ID number of an approved work preparation',
		questions:
			'For questions contact: \n Westfield Mall of Scandinavia - work.wmos@urw.se \n Westfield Täby centrum - work.wtc@urw.se \n Nacka Forum - work.nacka@urw.se',
		questions_NL:
			'For questions contact: \n Stadshart Amstelveen: Eelco.prinse@urw.com \n  Mall of the Netherlands: Christian.schultz@urw.com',

		idInput: 'Work preparation ID',
		idSubmit: 'Fill out Fire alarm shutdown & Hot work',
		idChecking: 'Checking ID number',
		idSubmitFail: 'Give a valid ID',
		idWrongFormat: 'Give ID the format XXXX-XX-XX',
		noAccess: 'You cant access this page without a valid ID',
		form: {
			title: 'Apply for Fire alarm shutdown & Hot work',
			applies: 'Applies to work preparation with ID: ',
			fireAlarmOrHotWork: 'Fire Alarm Shutdown or Hot work',
			fireAlarm: 'Fire Alarm Shutdown',
			hotWork: 'Hot work',
			workArea: 'Area/business/premises affected by the shutdown',
			shutdownReason: 'Reason for shutdown:',
			dateShutdown: 'Date for shutdown:',
			dateReconnect: 'Date for reconnect:',
			nameApplicant: 'Name contact person on site',
			phonenumberApplicant: 'Telephone number contact person on site',
			emailApplicant: 'Email contact person on site',
			companyApplicant: 'Company contact person on site',
			contactPerson: 'Contact person at the property owner',
			submitForm: 'Submit Fire alarm shutdown & Hot work',
			submittingForm: 'Preparing form...',
			uploadingData: 'Uploading data',
			submittedForm: 'Fire alarm shutdown & Hot work submitted',
			submittedFormError: 'Something went wrong, try again',
			times: 'Times when the fire alarm needs to be disconnected',
			timesInfo:
				'When switching off the fire alarm "ALL DAYS, AROUND THE CLOCK" a manned fire watch is required during the entire period',
			weekdays: 'Weekdays (Monday - Friday)',
			weekends: 'Weekends (Saturday + Sunday + public holidays)',
			aroundTheClock: 'All days, around the clock*',
			aroundTheClockWithoutInfo: 'All days, around the clock',
			creator: 'Contact person',
			contactPersonOnSite:
				'Contact person on site at the permit applicant during the disconnection',
			assentlyError:
				'Something went wrong with the eSign service. Make sure all emails are correct.',
			addContactPerson: 'Add contact person on site',
			infoBox: {
				title: 'Requirements when switching off the fire alarm:',
				bullet1:
					'The work may not begin until confirmation from the facility manager that the fire alarm has been disconnected as requested by the permit applicant.',
				bullet2: '',
				bullet3:
					'When the fire alarm is switched off, the permit applicant´s staff must always be within the closed area to quickly detect a fire and raise the alarm and try to extinguish the fire. This also applies during work breaks. The alarm must be raised by pressing an alarm button and contacting the guard. If the workplace is not staffed during the inspection, the permit is declared invalid and the fire alarm is activated. Alternatively, the disconnected area is separated from public parts in at least E30 or provided with building fire alarms.',
				bullet4:
					'Any costs incurred by the emergency services in the event of a fire alarm caused by the permit applicant´s negligence are paid for by the permit applicant. There is also an additional administrative cost of SEK 5,000 for Unibail-Rodamco-Westfield´s handling of the effort.',
				bullet4_NL:
					'Any costs incurred by the emergency services in the event of a fire alarm caused by the permit applicant´s negligence are paid for by the permit applicant. There is also an additional administrative cost of EUR 1,250 for Unibail-Rodamco-Westfield´s handling of the effort.',
				bullet5:
					'Evacuation alarms in public areas must be in operation.',
				bullet6:
					'*When switching off the fire alarm for "ALL DAYS, AROUND THE CLOCK" a manned fire watch is required during the entire period.',
			},
			stepper: {
				scope: 'Scope',
				sign: 'Sign',
			},
			submitted: 'Submitted',
			notSubmitted: 'E-sign was canceled',
			submittedInfoText:
				'The application is now signed by you, before the affected work can start, the application needs to be approved by the management. Decisions will be communicated via email. For questions regarding an application contact: \n \n Westfield Mall of Scandinavia: work.wmos@urw.se \n Westfield Täby Centrum: work.wtc@urw.se \n Nacka Centrum: work.nacka@urw.se',
			submittedInfoText_NL:
				'The work preparation is now signed by you, before the affected work can start, the work preparation needs to be approved by the management. Decisions will be communicated via email. For questions regarding a work preparation contact: \n \n Stadshart Amstelveen: Eelco.prinse@urw.com \n  Mall of the Netherlands: Christian.schultz@urw.com',
			notSubmittedInfoText:
				'Signing of the work was cancelled, a reminder will be sent to the specified email in one day',
			toStart: 'To start',
			tryAgain: 'Back to signing',
		},
		edit: {
			updating: 'Updating Fire alarm shutdown & Hot work...',
			updated: 'Fire alarm shutdown & Hot work updated!',
		},
	},
	serviceWork: {
		title: 'Service work',
		introText:
			'Approved service work must be presented by staff on site during work.',
		submittingServiceWork: 'Preparing the service work...',
		uploadingData: 'Uploading data',
		serviceWorkSubmitted: 'The service work is now submitted!',
		serviceWorkLogin: {
			title: 'Application for service work',
			info: 'The application for service works is made via the admin panel, and requires an account. Log in, or send an email to the address below to apply for an account.',
			loginInfo: 'Log in to the admin panel here!',
			emails: 'Application for account is made to: \n Westfield Mall of Scandinavia - work.wmos@urw.se \n Westfield Täby centrum - work.wtc@urw.se \n Nacka Forum - work.nacka@urw.se',
			subject: 'Enter subject: Application account.',
		},
	},
	workPrep: {
		title: 'Work preparation',
		introText:
			'Approved work preparation must be presented by staff on site during work.',
		workSteps: 'Work Steps (Brief description of what is to be performed)',
		relevantMall: 'Relevant mall',
		createdByCompany: 'Created by (company name)',
		createdByPersonName: 'Created by (name)',
		telephoneNumberToCreator: 'Phone number of the creator',
		emailToCreator: 'Email address of the creator',
		status: 'Status',
		statusChangedBy: 'Status changed by',
		establishedDate: 'Created',
		event: 'Event',
		createdBy: 'Created by',
		workArea: 'Work area (Shop, mall aisle, technology area, etc)',
		floor: 'Floor',
		numPeople: 'Number of people',
		storeIdOrName: 'Store ID/name',
		startDate: 'Start date',
		endDate: 'End Date',
		startingTime: 'Starting time',
		endingTime: 'Ending time',
		date: 'Date',
		signStatus: 'Sign status',
		signatureType: 'Signature type',
		projectManager: 'Tenant coordinator/ project manager',
		management: 'Management',

		hotWork: 'Hot work',
		hotWorkPermissionQuestion:
			'Does the work require hot work certificates?',
		hotWorkPermissionComment:
			'The application for Fire alarm shutdown & Hot work is done separately after the work preparation has been approved. Via the main menu, select "Fill out Fire alarm shutdown & Hot work" and fill in your ID number, which you will receive when the work preparation is approved.',
		hotWorkPermitManager: 'Hot work (Permit manager)',

		fireAlarmWillBeShutOff: 'Switched off fire alarm',
		fireAlarmWillBeShutOffQuestion:
			'Is the fire alarm required to be switched off?',
		fireAlarmWillBeShutOffComment:
			'The application for Fire alarm shutdown & Hot work is done separately after the work preparation has been approved. Via the main menu, select "Fill out Fire alarm shutdown & Hot work" and fill in your ID number, which you will receive when the work preparation is approved.',
		duringWhatTimes: 'During what times and dates?',

		contactDriftHotWork:
			'Contact the mall management of the relevant center at least 48 hours before the hot work.',
		contactDriftFireAlarm:
			'Contact the mall management of the relevant center at least 48 hours before the fire alarm shutdown.',
		contactDriftEmail:
			'<1>Westfield Mall of Scandinavia: </1><2>mos@vimini.se</2><3 /><4>Täby Centrum: </4><5>tabycentrum@propertypartner.se</5><6 /><7>Nacka Forum: </7><8>nacka@alova.se</8><9> (Phone number: 08 - 4080 89 40, Work hours: 07:00-17:00)</9>',
		contactDriftEmailShouldInclude: 'The email must include:',
		contactDriftEmailShouldIncludeHotWork:
			'<1>1. Title in subject "Unique ID number + hot work"</1><2 /><3>2. Approved work preparation in PDF format</3><4 /><5>3. Suggested date and time for the hot work</5><6 /><7>4. Contact for person in charge</7>',
		contactDriftEmailShouldIncludeFireAlarmWithLink:
			'<1>1. Title in subject "Unique ID number + fire alarm shutdown"</1><2 /><3>2. Completed form for the shutdown in PDF format. </3><4>Here you can download the form</4><5 /><6>3. Approved work preparation in PDF format</6>',
		contactDriftEmailShouldIncludeFireAlarm:
			'<1>1. Title in subject "Unique ID number + fire alarm shutdown"</1><2 /><3>2. Completed form for the shutdown in PDF format. </3><4 /><5>3. Approved work preparation in PDF format</5>',
		contactDriftContactPerson:
			'The application must always be made with a contact person from URW in copy',

		workWithWaterWillBeDoneQuestion:
			'Will work on watersystems be carried out?',
		workWithWaterCertificateComment:
			'Attach certificate from "Säker Vatten"',
		chooseWaterCertificate: 'Choose certificate from "Säker Vatten"',
		safeWaterComment:
			'Here you can read more about the<1> safe water certificate</1>',

		ventilationWillBeShutOff: 'Switched off ventilation',
		ventilationWillBeShutOffQuestion:
			'Is the ventilation required to be switched off?',
		ventilationWillBeShutOffComment1: 'Please note that if you select ',
		ventilationWillBeShutOffComment2: 'YES ',
		ventilationWillBeShutOffComment3:
			'on this, the centre´s operating company needs to investigate whether this is possible, which may involve a cost.',

		electricalWorkWillBeDoneQuestion:
			'Will electrical work be carried out?',
		electricalWorkWillBeDoneComment:
			'Here you can get the<1> certificate for the electrical installer from the Swedish Electrical Safety Authority</1>',

		electricalWorkCertificateComment:
			'Attach certificate from the Electrical Safety Agency',
		electricalWorkCertificateComment_NL:
			'Attach an instruction for electrical works',
		chooseElectricalWorkCertificate:
			'Choose certificate from the Electrical Safety Agency',
		sprinklerSystemWillBeShutOff: 'Switched off sprinkler system',
		sprinklerSystemWillBeShutOffQuestion:
			'Is the sprinkler systems required to be switched off?',
		sprinklerSystemWillBeShutOffComment1: 'Please note that if you select ',
		sprinklerSystemWillBeShutOffComment2: 'YES ',
		sprinklerSystemWillBeShutOffComment3:
			'on this, the centre´s sprinkler company needs to investigate whether this is possible, which may involve a cost.',

		constructionWorkWillTakePlaceQuestion:
			'Will a construction work take place?',
		constructionWorkWillTakePlaceComment:
			'What counts as<1> construction work</1>?<2 /><3>Here you will find more information about a work environment plan</3>',
		constructionWorkWillTakePlaceComment_NL:
			'<constructionWorkLink>Here you will find more information about the Health and safety plan</constructionWorkLink>',
		attachConstructionEnvironmentWorkPlanComment:
			'Attach a work environment plan',
		attachConstructionEnvironmentWorkPlanComment_NL:
			'Attach a health and safety plan',

		urwBaseU: 'BAS-U',
		urwBaseUExistsQuestion: 'Is there a BAS-U?',
		urwBaseUExistsComment:
			"The work preparation applies together with URW's guidelines for working environment with associated rules of procedure and protection, these must be available to the executor.<1 /><2>Here you will find more information about BAS-U/P</2>",
		nameToURWBaseU: 'Name to BAS-U',
		telephoneToURWBaseU: 'Telephone to BAS-U',
		emailToURWBaseU: 'Email to BAS-U',

		otherBaseU: 'Other BAS-U',
		otherBaseUExistsQuestion: 'Is there another BAS-U?',
		nameToOtherBaseU: 'Name to other BAS-U',
		telephoneToOtherBaseU: 'Phone to other BAS-U',
		emailToOtherBaseU: 'Email to other BAS-U',
		baseUCertificateComment: 'Attach BAS-U certificate',
		basePCertificateComment: 'Attach BAS-P certificate',
		chooseBaseUCertificate: 'Choose BAS-U certificate',
		chooseBasePCertificate: 'Choose BAS-P certificate',

		willTheWorkTakeLongerThan30DaysQuestion:
			'Will the work take more than 30 days and consist of more than 20 people at any one time employed at the same time?',
		willTheWorkTakeLongerThan30DaysQuestionShort:
			'Will the work take more than 30 days and consist of more than 20 people',
		willTheWorkTakeLongerThan30DaysComment:
			'Here you will find more information about<1> advance notification of construction sites</1>',
		willTheWorkTakeLongerThan30DaysCertificateComment:
			'Attach prior notification of the construction site to the Swedish Work Environment Authority',

		followUpInfoBric:
			'Follow-up of the work environment takes place via Infobric',
		onlyBiggerContracts:
			'Only for larger contracts, check with your client if required',
		registrationOfProject: 'Registration of projects takes place',

		attachScheduleForTheProjectComment: 'Attach schedule for the project',
		scheduleForTheProject: 'Schedule for the project',

		baseP: 'BAS-P',
		basePExistsQuestion: 'Is there a BAS-P?',
		basePExistsComment:
			"The work preparation applies together with URW's guidelines for working environment with associated rules of procedure and protection, these must be available to the executor.<1 /><2>Here you will find more information about BAS-U/P</2>",
		nameToBaseP: 'Name to BAS-P',
		telephoneToBaseP: 'Telephone to BAS-P',
		emailToBaseP: 'Email to BAS-P',

		projectManagerExistsQuestion:
			'Is there a tenant coordinator/project manager at URW?',
		nameToProjectManager: 'Name',
		telephoneToProjectManager: 'Phone number',
		emailToProjectManager: 'Email',

		insurance: 'Insurance',
		insuranceExistsQuestion: 'Insurance is available and is paid.',
		insuranceCompanyNameQuestion: 'Enter insurance company',
		insuranceCompanyNameQuestion_NL: 'Enter CAR-insurance company',
		insuranceContractNumberQuestion: 'Enter contract number',
		liabilityInsuranceNameQuestion: 'Enter liability insurance company',
		liabilityInsuranceContractNumberQuestion: 'Enter contract number',
		insuranceCompanyNameComment:
			'If you do not know the name, contact us as soon as possible after the submission of the work preparation when you have found out.',

		certainRisks: {
			question: 'What are the certain risks that occur?',
			comment:
				'"Certain risks" according to AFS 1999: 3 (2008: 16 §12) and other work environment risks.',
			comment_NL:
				'"Certain risks" according to the Working Conditions Act and other work environment risks.',
			fallRisk:
				'Risk of falling (when working at a height higher than 2 m)',
			hazardousChemOrBioSubstances:
				'Hazardous chemical or biological substances',
			heavyLifts: 'Heavy lifting of ... building elements',
			passingVehicleTraffic: 'Passing vehicle traffic',
			dust: 'Dust',
			vibrationsOrnoise: 'Vibrations / noise',
			fireHazard: 'Fire hazard',
			other: 'Other',
		},

		areaWhereWorkWillBePerformed: {
			question: 'Area, where will the work be carried out?',
			restaurant: 'Restaurant',
			store: 'Store',
			shoppingMallAisle: 'Shopping Mall Aisle',
			garage: 'Garage',
			technicalSpace: 'Technical Space',
			serviceCorridor: 'Service Corridor',
			workOutsideFacade: 'Work outside facade',
			squareOrEntrance: 'Square / Entrance',
			alreadyFilledInWithVGPlan: 'Already filled in with V&G-plan',
		},

		measureForWorkEnvironmentRisks: 'Measure for work environment risks',
		measureForWorkEnvironmentRisksComment:
			'Indicate the work steps where work environment risks exist as above and describe measures to reduce the risk of someone being injured.',

		validation: {
			atLeastOnePerson: 'Must be at least one person',
		},

		infoBox1: {
			title: 'Keep the following in mind when preparing the work steps',
			bullet1: 'Escape routes must not be blocked.',
			bullet2:
				'Equipment, lifts and materials must not be left in the work area after the end of the day and cleaning must take place daily.',
			bullet3:
				'All hot work requires a permit, if sprinklers and fire alarms are affected, this must be reported.',
			bullet4:
				'All staff working in the premises are required to present a valid ID06 at check-in.',
			bullet4_NL:
				'All staff working in the premises are required to present a valid ID card (EU)/passport/NL driver’s licence at check-in.',
			bullet5:
				'Fire seal between fire cells must be restored at the end of the working day.',
			bullet6:
				'The work area must be covered to minimize damage to property as a result of e.g. dust spreading etc. where required with regard to surrounding activities.',
			bullet7:
				'So called Dust-Control <1>SHOULD</1> be on the work surface during dust and smoke generating operations.',
			bullet8:
				'Measures such as roadblocks must be taken to protect third parties.',
			bullet9: 'Required protective equipment must be used.',
			bullet10:
				"In the event of a sharp fire alarm, the cost of calling the rescue service + an administrative cost of SEK 5,000 for Unibail-Rodamco-Westfield's handling of the operation. The cost is invoiced directly to the tenant who caused the evacuation.",
			bullet10_NL:
				"In the event of a sharp fire alarm, the cost of calling the rescue service + an administrative cost of EUR 1,250 for Unibail-Rodamco-Westfield's handling of the operation. The cost is invoiced directly to the tenant who caused the evacuation.",
			bullet11:
				'At ev. deviations in the workplace that are detected by the Administration during "Flying Control", the Administration has the right to terminate ongoing work depending on the type of error.',
		},

		infoBox2: {
			title: 'To consider when describing work steps',
			bullet1: 'Ventilation technical adaptations.',
			bullet2:
				"Vibration / Noise; Handling against tenants' ev. internal alarms, Work takes place after shops are closed.",
			bullet3:
				'Documentation; Self-checks and possibly deviation reports.',
			bullet4: 'All staff must wear ID06.',
			bullet4_NL:
				'All staff must have a valid ID card (EU)/passport/NL driver’s licence.',
			bullet5:
				'Garbage disposal. Where is the container set up, whose container is used?',
		},

		describeWorkSteps: 'Describe work steps',
		describeWorkStepsComment:
			'Think of method, material, equipment, workforce, etc.',

		additionalResources: {
			title: 'Resources in addition to materials',
			comment: 'For example, machines, aids and external resources.',
			scissorLift: 'Scissor lift',
			skylift: 'Skylift',
			container: 'Container',
			typeApprovedLadder: 'Type-approved ladder',
			otherAdditionalResource: 'Other',
			attachLiftCardsForPersonalWorkingWithLifts:
				'Attach liftcards for workers that will work with the lifts',
			chooseLiftCards: 'Choose liftcards',
		},

		gdpr: 'By filling in and submitting the work preparation, you agree that your personal data in the form of names and contact information is processed by the property owner and its parent company to administer and manage the work included in the work preparation. Your personal data will be stored up to a month after the end of the work. You have the right to exercise your rights (eg request access to or correction of personal data) according to the Data Protection Ordinance by contacting the property owner at dp.nordics@urw.com. It is your responsibility to ensure that the above information reaches the person whose personal data appears in the documentation.',
		gdpr_NL:
			'By filling in and submitting the work preparation, you agree that your personal data in the form of names and contact information is processed by the property owner and its parent company to administer and manage the work included in the work preparation. Your personal data will be stored up to a month after the end of the work. You have the right to exercise your rights (eg request access to or correction of personal data) according to the Data Protection Ordinance by contacting the property owner at data.protection@urw.com. It is your responsibility to ensure that the above information reaches the person whose personal data appears in the documentation.',

		warningText: `In the event of a triggered fire alarm, the cost of calling out the emergency services + an administrative fee cost of SEK 5,000 for Unibail-Rodamco-Westfield´s handling of the effort. The cost is invoiced directly to the tenant who caused the call.<br />
			Approved work preparation must be able to be shown at the request of authorized personnel at the workplace.<br />
			In the event of urgent events or questions regarding switching off the fire alarm during the course of the work or if the fire alarm is triggered in the work area, contact the team leader for guards immediately:<br /><br />
			Westfield Täby Centrum - 070 369 95 32<br />
			Westfield Mall Of Scandinavia - 08-586 230 13<br />
			Nacka Forum - 070 988 58 33<br /><br />
			In the case of fire protection measures, text messages must be sent to group leaders for guards after the end of the work.`,

		warningText_NL: `In the event of a triggered fire alarm, the cost of calling out the emergency services + an administrative fee cost of EUR 1,250 for Unibail-Rodamco-Westfield´s handling of the effort. The cost is invoiced directly to the tenant who caused the call.<br />
			Approved work preparation must be able to be shown at the request of authorized personnel at the workplace.<br />
			In the event of urgent events or questions regarding switching off the fire alarm during the course of the work or if the fire alarm is triggered in the work area, contact the team leader for guards immediately:<br /><br />
			Stadshart Amstelveen - 06 53935260<br />
			Mall of the Netherlands – 070 763 05 34<br /><br />
			In the case of fire protection measures, text messages must be sent to group leaders for guards after the end of the work.`,

		submittingWorkPrep: 'Submitting the work preparation...',
		workPrepSubmitted: 'The work preparation is now submitted!',
		pdfTooltip: 'Only PDF-files will be accepted',
		onlyPDF: 'Make sure to only attach PDF files ',
		assentlyError:
			'Something went wrong with the eSign service. Make sure all emails are correct, and that all PDFs are valid',
		uploadButton: 'Upload PDF',
		pdfSizeLimit: 'Maximum file size is 15 Mb',
		continue: 'Continue',
		back: 'Back',
		sign: 'Sign',
		save: 'Save changes',
		stepper: {
			creator: 'Creator',
			work: 'Work',
			requirements: 'Requirements',
			sign: 'Sign',
		},
		submitted: 'Submitted',
		notSubmitted: 'E-sign was canceled',
		submittedInfoText:
			'The work preparation is now signed by you, before the affected work can start, the work preparation needs to be approved by the management. Decisions will be communicated via email. For questions regarding a work preparation contact: \n \n Westfield Mall of Scandinavia: work.wmos@urw.se \n Westfield Täby Centrum: work.wtc@urw.se \n Nacka Centrum: work.nacka@urw.se',
		submittedInfoText_NL:
			'The work preparation is now signed by you, before the affected work can start, the work preparation needs to be approved by the management. Decisions will be communicated via email. For questions regarding a work preparation contact: \n \n Stadshart Amstelveen: Eelco.prinse@urw.com \n  Mall of the Netherlands: Christian.schultz@urw.com',
		notSubmittedInfoText:
			'Signing of the work preparation was cancelled, a reminder will be sent to the specified email in one day',
		toStart: 'To start',
		urlError:
			'Something went wrong with the eSign service. Please contact the mall management of the relevant center',
		iHaveRead: 'I have read the text above',
		tryAgain: 'Back to signing',
		isLoggedInFileInfo:
			'Below you will find your existing files. Please select a file from the list or upload a new PDF.',
		noDocument: 'No document',
		fireAlarmAndSprinklerInfo: {
			title: 'Note! Switched off fire alarm and sprinkler systems require you to notify the center sprinkler manager.',
			nacka: 'Linda Gricjus, linda.gricjus@a-sprinkler.se',
			taby: 'Jakob, 0704252295, jakob@a-sprinkler.se',
			mos: 'Fredric Björklund, 070-425 22 80, fredric.bjorklund@a-sprinkler.se',
		},
		fireAlarmAndSprinklerInfo_NL: {
			title: 'Note! Switched off fire alarm and sprinkler systems require you to notify the center sprinkler manager.',
			amstelveen: 'robert.belt@firesolutions.cegelec.com',
			mallOfTheNetherlands: 'servicewoerden@degrootgroep.nl',
		},
		clientTitle: 'Client',
		clientName: 'Client Name',
		clientTelephone: 'Client phone number',
		clientEmail: 'Client email',
		uploadingFiles: 'Uploading files',
		workEnviromentResponsibility: {
			title: 'I confirm that I, as issuer, am aware that',
			bullet1:
				'I, as employer, have responsibility for the work environment for my employees,',
			bullet2:
				'the client (either the store or Unibail-Rodamco-Westfield) has responsibility for the work environment as the developer (Sw. byggherre), and',
			bullet2_NL:
				'the client (either the store or Unibail-Rodamco-Westfield) has responsibility for the work environment as the developer (nl. bouwheer), and',
			bullet3:
				'Unibail-Rodamco-Westfield, as the property owner, only has responsibility for coordinating work environment matters for the center, in accordance with the Work Environment Act ch. 3, section 7 d, and that this does not deprive neither the client, nor the BAS-U, the BAS-P or any employer of their respective work environment responsibilities.',
			bullet3_NL:
				'Unibail-Rodamco-Westfield, as the property owner, only has responsibility for coordinating work environment matters for the center and that this does not deprive neither the client or any employer of their respective work environment responsibilities.',
		},
	},
	workPrepPDF: {
		hotWorkPermitManager: 'Hot work (Permit manager)',
		fireAlarmWillBeShutOffTimePeriod:
			'Switched off fire alarm (Time period)',
		switchedOffVentilation: 'Switched off ventilation',
		switchedOffVentilationTimePeriod:
			'Switched off ventilation (Time period)',
		switchedOffSprinklerSystem: 'Switched off sprinkler system',
		switchedOffSprinklerSystemTimePeriod:
			'Switched off sprinkler system (Time period)',
		clientName: 'Client Name',
		clientTelephone: 'Client phone nnumber',
		clientEmail: 'Client email',
		projectManagerExist: 'Tenant coordinator/project manager exists',
		projectManagerName: 'Tenant coordinator/project manager name',
		projectManagerPhoneNumber:
			'Tenant coordinator/project manager phone number',
		projectManagerEmailAddress:
			'Tenant coordinator/project manager email address',
		urwBasUExist: 'BAS-U exists',
		urwBasUName: 'BAS-U name',
		urwBasUPhoneNumber: 'BAS-U phone number',
		urwBasUEmailAddress: 'BAS-U email address',
		basPExist: 'BAS-P exists',
		basPName: 'BAS-P name',
		basPPhoneNumber: 'BAS-P phone number',
		basPEmailAddress: 'BAS-P email address',
		insuranceExist: 'Insurance exists',
		insuranceCompany: 'Insurance company',
		liabilityInsuranceCompany: 'Liability insurance company',
		insuranceContactNumber: 'Contract number',
		certainRisks: 'Certain risks',
		remediesForWorkEnvironmentRisks: 'Remedies for work environment risks',
		additionalResources: 'Additional resources',
		area: 'Area',
		descriptionOfWorkSteps: 'Description of work steps',
		errorOccured: 'One or more of the PDF files could not be merged',
	},
	adminPanel: {
		title: 'Admin panel',
		workpreps: 'Work preparations',
		helperText:
			'Click on a row to see details about a specific work preparation.',
		all: 'All',
		archived: 'Archived',
		notArchived: 'Not Archived',
		newWorkPrep: 'New work preparation',
		search: 'Search work preperation',
		searchId: 'Search ID',
		searchCompany: 'Search company',
		searchName: 'Search name',
		allMall: 'All',
		relevantMall: 'Relevant mall',
		activeWorks: 'Active works',
	},
	fireAlarmPanel: {
		fireAlarms: 'Fire alarm shutdowns & Hot work',
		helperText: 'Click on a row to see details about a specific work.',
		type: 'Type',
		newFireAlarm: 'Apply for Fire alarm shutdown & Hot work',
		search: 'Search work',
	},
	serviceWorkPanel: {
		serviceWorks: 'Service works',
		helperText: 'Click on a row to see details about a service work.',
		newServiceWork: 'New Service work',
		search: 'Search service work',
	},
	fireAlarmDetails: {
		title: 'Fire alarm shutdown & Hot work',
		created: 'Created',
		updatingStatus: 'Updating status in database ...',
		statusUpdated: 'Status updated!',
		fireAlarmOrHotWork: 'Fire Alarm Shutdown or Hot work',
		fireAlarm: 'Fire Alarm Shutdown',
		hotWork: 'Hot work',
		workArea: 'Area/business/premises affected by the shutdown',
		shutdownReason: 'Reason for shutdown:',
		approve: 'Approve work',
		reject: 'Reject work',
		remove: 'Remove work',
		openPDF: 'Open as PDF',
		relevantMall: 'Relevant mall',
		startDate: 'Start date',
		startingTime: 'Starting time',
		endDate: 'End date',
		endingTime: 'Ending time',
		createdByCompany: 'Created by (company name)',
		createdByName: 'Created by (name)',
		phoneNumber: 'Phone number of the creator',
		email: 'Email address of the creator',
		workPrepStore: 'Work preparation: Store ID/Name',
		workPrepFloor: 'Work preparation: Floor',
		workPrepWorkArea: 'Work preparation: Work Area',
		drift: 'Drift',
		scrollMenu: {
			workPrep: 'Work Preparation',
			overview: 'Overview',
			creator: 'Created by',
			signStatus: 'Sign status',
		},
		toWorkprep: 'To workprep',
		edit: 'Make changes in work',
		completionRequest: 'Request a completion of work',
	},
	serviceWorkDetails: {
		title: 'Service Works',
		created: 'Created',
		updatingStatus: 'Updating status in database ...',
		statusUpdated: 'Status updated!',
		approve: 'Approve Service Work',
		reject: 'Reject Service work',
		remove: 'Remove Service Work',
		openPDF: 'Open as PDF',
		relevantMall: 'Relevant mall',
		startDate: 'Start date',
		startingTime: 'Starting time',
		endDate: 'End date',
		endingTime: 'Ending time',
		createdByCompany: 'Created by (company name)',
		createdByName: 'Created by (name)',
		phoneNumber: 'Phone number of the creator',
		email: 'Email address of the creator',
		store: 'Store ID/Name',
		floor: 'Floor',
		workArea: 'Work area',
		numPeople: 'Number of people',
		workSteps: 'Work Steps',
		scrollMenu: {
			overview: 'Overview',
			creator: 'Created by',
			signStatus: 'Sign status',
		},
		makeChanges: 'Edit Service work',
		completeWorkPrepRequest: 'Request a completion',
	},
	fireAlarmPDF: {
		title: 'Fire alarm shutdown & Hot work',
	},
	workPrepDetails: {
		created: 'Created',
		updating: 'Updating status in database ...',
		updated: 'Status updated!',
		newSignatureRequest: 'Request new signatures manually',
		newSignatureRequested: 'Request sent!',
		newSignatureRequestTooltip:
			'Use only if workprep was manually edited in admin panel',
		approveTooltip:
			'You can only approve a work preparaion that has been signed by everyone involved',
		completeWorkPrepRequest: 'Request a completion of the work preparation',
		completeWorkPrepRequestModal: {
			title: 'Completion request',
			completionRequestMessageTitle: 'Message',
			submitRequest: 'Send completion request',
			closeModal: 'Close',
			sendingRequest: 'Sending request...',
			requestSent: 'Completion request sent!',
		},
		commentSection: 'Comments',
		commentWorkPrep: 'Add comment',
		commentModal: {
			title: 'Comment work preperation',
			messageHeaderTitle: 'Heading',
			messageTitle: 'Comment',
			submitRequest: 'Add comment',
			addingComment: 'Adding comment...',
			closeModal: 'Close',
			requestSent: 'Comment added!',
			commentCreator: 'Name',
		},
		approve: 'Sign work preperation as management',
		approveModal: {
			title: 'Approve work',
			messageInfo: 'Message will be included in email',
			messageTitle: 'Message',
			submitRequest: 'Approve work',
			closeModal: 'Close',
		},
		rejectModal: {
			title: 'Reject work',
			messageInfo: 'Message will be included in email',
			messageTitle: 'Message',
			submitRequest: 'Reject',
			closeModal: 'Close',
		},
		deleteModal: {
			title: 'Remove work',
			helperText: 'This action can NOT be undone',
			submitRequest: 'Remove work',
			closeModal: 'Close',
		},
		sendReminder: 'Send reminder for signing',
		reminderSent: 'Reminder sent',
		noReminder: 'No active signing found',
		reminderSentError: 'The eSign service gave an error, no reminder sent',
		issuedBy: 'Issued by',
		date: 'Date',
		message: 'Message',
		completionRequested: 'Requested completion',
		lastEdited: 'Last edited',
		lastReminded: 'Last reminder',
		updates: 'Updates',
		Touch: 'Touch',
		Sms: 'Sms',
		ElectronicId: 'Mobil BankID',
		AdminPanel: 'Admin Panel',
		scrollMenu: {
			overview: 'Overview',
			creator: 'Created by',
			requirements: 'Requirements',
			safety: 'Safety',
			signStatus: 'Sign status',
			comments: 'Comments',
		},
		attachments: 'Other attachments',
		newFireAlarm: 'Fill out Fire alarm shutdown & Hot work',
		signProjectManager: 'Sign work preperation as project manager',
		signProjectManagerToolTip:
			'Creator of work preperation must sign before project manager can sign',
		event: 'Event',
		relevantMall: 'Relevant mall',
		numberOfPeople: 'Number of people',
		workArea: 'Work area',
		floor: 'Floor',
		storeIdOrName: 'Store ID/Name',
		startDate: 'Start date',
		endDate: 'End date',
		startingTime: 'Starting time',
		endingTime: 'Ending time',
		scheduleForTheProject: 'Schedule for the project',
		createdByCompany: 'Created by (company name)',
		createdByPerson: 'Created by (name)',
		telephoneNumber: 'Phone number of the creator',
		email: 'Email address of the creator',
		insuranceExists: 'Insurance exists',
		insuranceCompanyName: 'Insurance company',
		certainRisks: 'Certain risks',
		remediesForWorkEnvironmentRisks: 'Remedies for work environment risks',
		area: 'Area',
		descriptionOfWorkSteps: 'Description of work steps',
		additionalResources: 'Additional resources',
		projectManagerExists: 'Tenant coordinator/project manager exists',
		projectManagerName: 'Tenant coordinator/project manager (name)',
		projectManagerEmail:
			'Tenant coordinator/project manager (email address)',
		projectManagerPhone:
			'Tenant coordinator/project manager (phone number)',
		openAsPDF: 'Open as PDF',
		makeChangesInWorkPrep: 'Make changes in work preparation',
		rejectWorkPrep: 'Reject work preparation',
		deleteWorkPrep: 'Delete work preparation',
		urwBaseUExists: 'BAS-U exist',
		urwBaseUName: 'BAS-U (Name)',
		urwBaseUPhone: 'BAS-U (Phone number)',
		urwBaseUEmail: 'BAS-U (Email)',
		basePExists: 'BAS-P exist',
		basePName: 'BAS-P (Name)',
		basePPhone: 'BAS-P (Phone number)',
		basePEmail: 'BAS-P (Email)',
		notificationStatus: {
			pending: 'Email status is being checked, come back soon...',
			delivered: 'Email for signing has been delivered.',
			undeliverable:
				'Email for signature could not be delivered. Please start by sending a reminder, then please verify that the email address is correct.',
		},
	},
	editWorkPrep: {
		completeRequest: {
			sent: 'Complete request fulfilled',
		},
		workPrepUpdated: 'Work preperation updated!',
		notAllRequiredFiles:
			'Make sure you have uploaded all required files, in PDF format',
		uploadedFiles: 'Uploaded files:',
		requestNewSignaturesTitle: 'New signatures',
		requestNewSignatures:
			'Request new signatures in connection with changes',
		warning: 'Be careful, changes are not reversible!',
	},
	login: {
		userDoesNotExist: "The user you entered doesn't exist.",
		wrongPassword: 'The user exist, but the password is wrong.',
		wrongCredentials: 'Wrong email or password.',
		somethingWentWrong: 'Something went wrong, try again.',
		login: 'Log in',
		email: 'Email',
		password: 'Password',
		forgotPassword: 'Forgot password?',
		forgotPasswordPage: {
			send: 'Send',
			sendingEmail: 'Sending email...',
			emailSent: 'An email about password reset has been sent!',
		},
	},
	common: {
		lang: 'en-US',
		back: 'Back',
		cancel: 'Cancel',
		notLoggedInMsg: 'You have to <1>sign in</1> first!',
		erorOccured: 'There was an error!',
		required: 'Required',
		atLeastOne: 'You need to pick at least one alternative',
		yes: 'Yes',
		no: 'No',
		invalidEmailAddress: 'Invalid email address',
		invalidDate: 'The date is formatted incorrectly',
		invalidTime: 'The time is formatted incorrectly',
		mustBeInteger: 'Must be a integer',
		passwordDoesNotMatch: "Passwords don't match",
		seeErrorsAbove: 'Please review the error messages above!',
		here: 'here',
		fileRequired: 'File attachment is required',
		electricalWorkCertificate:
			'Certificate from the Electrical Safety Agency',
		workWithWaterCertificate: 'Certificate from "Säker Vatten"',
		scheduleForTheProject: 'Schedule for the project',
		constructionEnvironmentWorkPlan: 'Work environment plan',
		willTheWorkTakeLongerThan30DaysCertificate:
			'Prior notification of the construction site (Swedish Work Environment Authority)',
		baseUCertificate: 'BAS-U certificate',
		basePCertificate: 'BAS-P certificate',
		attachedFiles: 'Attached files',
		liftCardsForPersonalWorkingWithLifts:
			'Liftcards for the persons working with lifts',
		waitPlease: 'Please wait...',
		beforeToday: 'Select an upcoming date',
		beforeStart: 'Select a date after start date',
		badRedirectUUID: 'The link is no longer valid!',
		toStart: 'To start',
		projectManager: 'Project manager',
		confirmPrivacyPolicy:
			'I have read and agree to the <privacy>Privacy Policy</privacy>',
	},
	adminMenu: {
		workPrep: 'Work Preparations',
		awaitingApproval: 'Awaiting Approval',
		all: 'All',
		fireShutdown: 'Fire Alarm Shutdowns & Hot work',
		serviceJobs: 'Service Works',
		workHistory: 'Work History',
		makeAccount: 'Create User',
		logOut: 'Log Out',
		lang: 'Svenska',
		account: 'User',
		accounts: 'Users',
		handleAccount: 'Manage user',
		handleAccounts: 'Manage users',
		documentCollection: 'Document collection',
		editAccount: 'User details',
		active: 'Active',
		finished: 'Finished',
	},
	workPrepStatusDisplay: {
		status: {
			approved: 'Approved',
			awaitingSigning: 'Awaiting signing',
			denied: 'Rejected',
			awaitingReview: 'Awaiting review',
		},
	},
	fileUrlSingle: {
		hide: 'Hide certificate',
		show: 'Show certificate',
	},
	submitUserDocumentModal: {
		uploadDocument: 'Upload document',
		descriptionText:
			'Add text that describes what it means to upload document here',
		documentName: 'Document name',
		documentPerson: 'Associated person',
		documentType: 'Document type',
		selectDocument: 'Select file',
		electricalWorkCertificate: 'Electric certificate',
		workWithWaterCertificate: 'Safe water certificate',
		baseUCertrificate: 'Bas-U certificate',
		basePCertificate: 'Bas-P certificate',
		liftCardsForPersonalWorkingWithLifts: 'Lift certificate',
		openLegalText: 'terms',
		agreeLegal: 'I have read, and agree with these',
		legalTitle: 'Document handling',
		legalText:
			'On this page, you are given the opportunity to upload and save certificates and other similar documentation to prove authorization to carry out work in Unibail-Rodamco-Westfield´s facilities. Such documentation is saved in the system for 12 months and is automatically deleted thereafter unless you choose to extend the storage period. You can also delete uploaded documents at any time. The purpose behind the possibility to store the documents is to facilitate recurring work and the creation of new work preperations in this portal. The documents on this page are visible to those logged in to the user account. It is your responsibility to ensure that the above information reaches the natural person whose personal data appears in the documentation.',
		cancel: 'Cancel',
	},
	userDocuments: {
		documentCollection: 'Document collection',
		documentCollectionDescription:
			'On this page you can view your uploaded documents, renew documents, delete documents and upload new documents.',
		uploadDocument: 'Upload document',
		document: 'DOCUMENT',
		name: 'NAME',
		type: 'TYPE',
		expiryDate: 'EXPIRY DATE',
		electricalWorkCertificate: 'ELECTRIC CERTIFICATE',
		workWithWaterCertificate: 'SAFE WATER CERTIFICATE',
		basePCertificate: 'BAS-P CERTIFICATE',
		baseUCertificate: 'BAS-U CERTIFICATE',
		liftCardsForPersonalWorkingWithLifts: 'LIFT CERTIFICATE',
		deleteUserDocumentModal: {
			confirmDeletion: 'Are you sure you want to delete the document?',
			deletionExplainer: 'Describe what it means to delete a document',
			delete: 'Delete',
			abort: 'Cancel',
		},
		renewDocument: 'Renew document',
		renewingDocument: 'Renewing document...',
		documentRenewed: 'Document renewed!',
		uploadingDocument: 'Uploading document...',
		documentUploaded: 'Document uploaded!',
		deletingDocument: 'Deleting document...',
		documentDeleted: 'Document deleted!',
	},
	userSettings: {
		handleAccount: 'User details',
		resetPassword: 'Reset password',
		name: 'Name',
		email: 'Email',
		role: 'Role',
		password: 'Password',
		save: 'Save',
		cancel: 'Cancel',
		edit: 'Edit',
		stopEdit: 'Stop edit',
		delete: 'Delete user',
		resetPasswordInfo:
			'By clicking the "Reset password" button, an email with reset instructions will be sent to the email associated with the user.',
		deleteUser: {
			confirmDeletion: 'Are you sure you want to delete the user?',
			helperText: 'This action can NOT be undone',
			abort: 'Cancel',
			delete: 'Delete',
		},
	},
	userPanel: {
		title: 'Edit users',
		helperText: 'Click on a row to see details about a specific user',
		name: 'Name',
		email: 'Email',
		role: 'Role',
		editDelete: 'Edit/Delete',
		newUser: 'Create new user',
		search: 'Search user',
		searchRole: 'Role',
		noRole: 'All',
	},
	info: {
		newPortalTitle: 'We have a new portal!',
		newPortal:
			'If you experience problems, please contact support@weknowit.se',
	},
	workHistory: {
		title: 'Work history',
		helperText: 'Below is a list of previous work done by the creator.',
		searchCompany: 'Search company',
		answerYes: 'Yes',
		answerNo: 'No',
		workPrepTitle: 'Work preparations',
		fireAlarmTitle: 'Fire alarm shutdowns and Hot works',
		serviceWorkTitle: 'Service works',
		creatorHistoryTitle: 'Creator history',
	},
	workHistoryByCompany: {
		title: 'Work history',
		helperText: 'Below is a list of previous work done by the creator.',
		answerYes: 'Yes',
		answerNo: 'No',
		workPrepTitle: 'Work preparations',
		fireAlarmTitle: 'Fire alarm shutdowns and Hot works',
		serviceWorkTitle: 'Service works',
		creatorHistoryTitle: 'Creator history',
	},
	tooltips: {
		workPrep: {
			workSteps:
				'Briefly describe the work steps to be performed, focusing on the tasks included in the work, such as: <br/><br/><b>Examples:</b><br/><br/><b>Replacing lighting in the shopping mall aisle: </b>Installing new lamps and fixtures in the ceiling.<br/><b>Installing ventilation in technical spaces: </b>Mounting ducts and fans for improved air circulation.<br/><b>Demolition and renovation of store areas: </b>Removing old walls, installing new partitions, and preparing for flooring.<br/><b>Painting and surface renovation in restaurants: </b>Spackling and painting walls, as well as replacing flooring materials.<br/><b>Installing signs on the facade: </b>Mounting new signs and advertising materials outdoors.<br/><b>Maintenance work in the garage: </b>Repairing lighting and ventilation systems.<br/><b>Servicing fire protection systems: </b>Testing and replacing sprinklers and smoke detectors.',
			workSteps_NL:
				'Briefly describe the work steps to be performed, focusing on the tasks included in the work, such as: <br/><br/><b>Examples:</b><br/><br/><b>Replacing lighting in the shopping mall aisle: </b>Installing new lamps and fixtures in the ceiling.<br/><b>Installing ventilation in technical spaces: </b>Mounting ducts and fans for improved air circulation.<br/><b>Demolition and renovation of store areas: </b>Removing old walls, installing new partitions, and preparing for flooring.<br/><b>Painting and surface renovation in restaurants: </b>Spackling and painting walls, as well as replacing flooring materials.<br/><b>Installing signs on the facade: </b>Mounting new signs and advertising materials outdoors.<br/><b>Maintenance work in the garage: </b>Repairing lighting and ventilation systems.<br/><b>Servicing fire protection systems: </b>Testing and replacing sprinklers and smoke detectors.<br/>',
			workArea:
				'Specify the area where the work will be carried out, such as: <br/><br/><b>Examples:</b><br/><br/><b>Shop: </b>Inside a store, including storage areas and display sections.<br/><b>Mall aisle: </b>Common areas between stores used by shoppers.<br/><b>Technology area: </b>Technical rooms for electrical and HVAC systems.<br/><b>Garage: </b>Parking facilities, including ramps and entrances.<br/><b>Service corridor: </b>Access passages for staff and deliveries.<br/><b>Restaurant: </b>Dining areas, kitchens, and serving zones.<br/><b>Outside facade: </b>Exterior walls and entrance areas.<br/><b>Square/Entrance: </b>Open spaces and main entrances to the shopping mall.',
			workArea_NL:
				'Specify the area where the work will be carried out, such as: <br/><br/><b>Examples:</b><br/><br/><b>Shop: </b>Inside a store, including storage areas and display sections.<br/><b>Mall aisle: </b>Common areas between stores used by shoppers.<br/><b>Technology area: </b>Technical rooms for electrical and HVAC systems.<br/><b>Garage: </b>Parking facilities, including ramps and entrances.<br/><b>Service corridor: </b>Access passages for staff and deliveries.<br/><b>Restaurant: </b>Dining areas, kitchens, and serving zones.<br/><b>Outside facade: </b>Exterior walls and entrance areas.<br/><b>Square/Entrance: </b>Open spaces and main entrances to the shopping mall.',
			numPeople:
				'Specify the total number of people who will be involved in the work, including workers, supervisors, and any subcontractors.',
			numPeople_NL:
				'Specify the total number of people who will be involved in the work, including workers, supervisors, and any subcontractors.',
			floor: 'Specify the floor where the work will be carried out in the shopping mall. Include additional details if needed, such as proximity to entrances or specific areas. <br/><br/><b>Examples:</b><br/><br/><b>Ground floor: </b>Near main entrances or stores.<br/><b>First floor: </b>In the restaurant area or additional shopping spaces.<br/><b>Basement: </b>In garages or technical rooms.<br/><b>Rooftop: </b>For ventilation or signage installations.',
			floor_NL:
				'Specify the floor where the work will be carried out in the shopping mall. Include additional details if needed, such as proximity to entrances or specific areas. <br/><br/><b>Examples:</b><br/><br/><b>Ground floor: </b>Near main entrances or stores.<br/><b>First floor: </b>In the restaurant area or additional shopping spaces.<br/><b>Basement: </b>In garages or technical rooms.<br/><b>Rooftop: </b>For ventilation or signage installations.',
			storeIdOrName:
				'Enter the Store ID if you have the number; otherwise, the store name is also acceptable. This information helps identify the exact location for planning and coordination. <br/><br/><b>Examples:</b><br/><br/><b>Store ID: </b>1023<br/><b>Name: </b>Gourmet Grill.',
			storeIdOrName_NL:
				'Enter the store name. This information helps identify the exact location for planning and coordination. <br/><br/><b>Examples:</b><br/><br/><b>Store ID: </b>Berkenhove 4<br/><b>Name: </b>Samsung.',
			insuranceCompanyNameQuestion:
				'Enter the name of the insurance company that covers potential damages or accidents related to the work. Make sure to provide the full name for accurate documentation. <br/><br/><b>Examples:</b><br/><br/>Försäkringsbolag AB.',
			insuranceCompanyNameQuestion_NL:
				'Enter the name of the CAR-insurance company that covers the work being carried out. Make sure to provide the full name for accurate documentation. <br/><br/><b>Examples:</b><br/><br/>ABC Insurance Ltd.',
			insuranceContractNumberQuestion:
				'Enter the contract number provided by the insurance company for the insurance policy. This number is used to verify coverage and facilitate claims if necessary. <br/><br/><b>Example:</b><br/><br/><b>Contract number: </b>12345678.',
			insuranceContractNumberQuestion_NL:
				'Enter the contract number provided by the insurance company for the CAR-insurance policy. This number is used to verify coverage and facilitate claims if necessary.',
			liabilityInsurance:
				'Enter the name of the liability insurance company that covers potential damages or accidents related to the work. Make sure to provide the full name for accurate documentation. <br/><br/><b>Examples:</b><br/><br/>Secure Liability Insurance Ltd.',
			liabilityInsuranceContractNumber:
				'Enter the contract number provided by the insurance company for the liability insurance policy. This number is used to verify coverage and facilitate claims if necessary.',
			clientName:
				'Enter the name of the person who has ordered the work. Make sure to provide the full name. <br/><br/><b>Example:</b><br/><br/>John Doe.',
			clientName_NL:
				'Enter the email address of the person who has ordered the work. <br/><br/><b>Example:</b><br/><br/>john.doe@example.com',
			clientTelephone:
				'Enter the phone number of the person who has ordered the work. Include the country code if applicable. <br/><br/><b>Example:</b><br/><br/>+46 70 123 45 67 (with country code).',
			clientTelephone_NL:
				'Enter the phone number of the person who has ordered the work. Include the country code if applicable.',
			clientEmail:
				'Enter the email address of the person who has ordered the work. Make sure it is correctly formatted to ensure smooth communication. <br/><br/><b>Example:</b><br/><br/>john.doe@example.com.',
			clientEmail_NL:
				'Enter the email address of the person who has ordered the work. <br/><br/><b>Example:</b><br/><br/>john.doe@example.com',
			projectManagerExistsQuestion:
				'Enter the name, phone number, and email of the tenant coordinator or project manager at URW if available. Make sure the information is accurate for effective communication. <br/><br/><b>Examples:</b><br/><br/><b>Name: </b>John Doe<br/><b>Phone: </b>+46 70 123 45 67<br/><b>Email: </b>john.doe@urw.com.',
			projectManagerExistsQuestion_NL:
				'Enter the name, phone number, and email of the tenant coordinator or project manager at URW if available. Make sure the information is accurate for effective communication. <br/><br/><b>Examples:</b><br/><br/><b>Name: </b>John Doe<br/><b>Phone: </b>+31 70 123 45 67<br/><b>Email: </b>john.doe@urw.com',
			nameToURWBaseU:
				'Enter the name of the person appointed as BAS-U (Construction Work Environment Coordinator for Execution) in the project. BAS-U is responsible for coordinating work environment measures during the construction phase and ensuring a safe work environment on-site. <br/><br/><b>Example:</b><br/><br/><b>Name: </b>Johan Andersson.',
			telephoneToURWBaseU:
				'Enter the phone number of the person appointed as BAS-U (Construction Work Environment Coordinator for Execution). This number is used for quick contact regarding work environment and safety during the construction phase. <br/><br/><b>Example:</b><br/><br/>+46 70 123 45 67.',
			emailToURWBaseU:
				'Enter the email address of the person appointed as BAS-U (Construction Work Environment Coordinator for Execution). Email is used for written communication and documentation of work environment measures. <br/><br/><b>Example:</b><br/><br/>johan.andersson@byggprojekt.se.',
			nameToBaseP:
				'Enter the name of the person appointed as BAS-P (Construction Work Environment Coordinator for Planning and Design) in the project. BAS-P is responsible for ensuring that work environment risks are considered during the design phase and that safety measures are planned in advance. <br/><br/><b>Example:</b><br/><br/><b>Name: </b>Johan Andersson.',
			telephoneToBaseP:
				'Enter the phone number of the person appointed as BAS-P (Construction Work Environment Coordinator for Planning and Design). This number is used for quick contact regarding work environment risks and safety measures during the planning phase. <br/><br/><b>Example:</b><br/><br/>+46 70 123 45 67.',
			emailToBaseP:
				"Enter the email address of the person appointed as BAS-P (Construction Work Environment Coordinator for Planning and Design). Email is used for written communication and documentation of work environment measures during the project's planning phase. <br/><br/><b>Example:</b><br/><br/>johan.andersson@byggprojekt.se.",
			measureForWorkEnvironmentRisksComment:
				'Describe the tools, equipment or other safety measures that will be used and taken to mitigate and minimize the risks of accidents and other work environment disturbances. <br/><br/><b>Examples:</b><br/><br/><b>Risk of falling: </b>Use of safety harness and guardrails.<br/><b>Hazardous substances: </b>Use of protective gloves and masks.<br/><b>Heavy lifting: </b>Use of lifting equipment and proper techniques.<br/><b>Passing vehicle traffic: </b>Use of barriers and warning signs.<br/><b>Dust: </b>Use of mask and glasses.<br/><b>Vibrations / noise: </b>Use of vibration-dampening gloves and ear protection.<br/><b>Fire hazard: </b>Use of fire extinguishers and fireproof materials.',
			measureForWorkEnvironmentRisksComment_NL:
				'<b>Examples:</b><br/><br/><b>Risk of falling: </b>Use of safety harness and guardrails.<br/><b>Hazardous substances: </b>Use of protective gloves and masks.<br/><b>Heavy lifting: </b>Use of lifting equipment and proper techniques.<br/><b>Passing vehicle traffic: </b>Use of barriers and warning signs.<br/><b>Dust: </b>Use of mask and glasses.<br/><b>Vibrations / noise: </b>Use of vibration-dampening gloves and ear protection.<br/><b>Fire hazard: </b>Use of fire extinguishers and fireproof materials.',
			describeWorkSteps:
				'Describe the work steps, considering the method, materials, equipment, and workforce required. Try to be specific to ensure clear understanding and effective planning. <br/><br/><b>Example:</b><br/><br/><b>Method: </b>Installing ventilation ducts.<br/><b>Material: </b>Metal ducts and connectors.<br/><b>Equipment: </b>Ladders, screwdrivers, and safety harness.<br/><b>Workforce: </b>2 installers.',
			describeWorkSteps_NL:
				'Describe the work steps, considering the method, materials, equipment, and workforce required. Try to be specific to ensure clear understanding and effective planning. <br/><br/><b>Example:</b><br/><br/><b>Method: </b>Installing ventilation ducts.<br/><b>Material: </b>Metal ducts and connectors.<br/><b>Equipment: </b>Ladders, screwdrivers, and safety harness.<br/><b>Workforce: </b>2 installers.',
			sprinklerSystemWillBeShutOffQuestion:
				'Specify when the sprinkler system needs to be switched off and provide the exact times and dates for the shutdown. This is important for planning fire protection measures and ensuring proper coordination. <br/><br/><b>Example:</b><br/><br/><b>Day 1: </b>2025-04-10 <b>Time: </b>08:00 - 16:00<br/><b>Day 2: </b>2025-04-11 <b>Time: </b>08:00 - 16:00<br/><b>Day 3: </b>2025-04-12 <b>Time: </b>08:00 - 16:00.',
			ventilationWillBeShutOffQuestion:
				'Specify if the ventilation system needs to be switched off and provide the exact times and dates for the shutdown. This is important to ensure a safe working environment and to avoid impacts on other businesses in the building. <br/><br/><b>Example:</b><br/><br/><b>Day 1: </b>2025-04-10 <b>Time: </b>08:00 - 16:00<br/><b>Day 2: </b>2025-04-11 <b>Time: </b>08:00 - 16:00<br/><b>Day 3: </b>2025-04-12 <b>Time: </b>08:00 - 16:00.',
		},
		fireAlarmShutdown: {
			workArea:
				'Specify the areas, businesses, or premises that will be affected by the shutdown. Be clear about which parts of the shopping mall or stores will be impacted to help with planning and communication. <br/><br/><b>Examples:</b><br/><br/><b>Area: </b>First-floor aisle near electronics stores.<br/><b>Business: </b>Fashion Hub and Gourmet Grill.<br/><b>Premises: </b>Storage room and technical space in the basement.',
			workArea_NL:
				'Specify the areas, businesses, or premises that will be affected by the shutdown. Be clear about which parts of the shopping mall or stores will be impacted to help with planning and communication. <br/><br/><b>Examples:</b><br/><br/><b>Area: </b>First-floor aisle near electronics stores.<br/><b>Business: </b>Fashion Hub and Gourmet Grill.<br/><b>Premises: </b>Storage room and technical space in the basement.',
			shutdownReason:
				'Specify the reason for the shutdown clearly and concisely. Describe why the shutdown is necessary to help with planning and communication. <br/><br/><b>Examples:</b><br/><br/><b>Reason: </b>Electrical maintenance and safety inspections.<br/><b>Reason: </b>Installation of new ventilation system.<br/><b>Reason: </b>Fire alarm testing and system updates.',
			shutdownReason_NL:
				'Specify the reason for the shutdown clearly and concisely. Describe why the shutdown is necessary to help with planning and communication. <br/><br/><b>Examples:</b><br/><br/><b>Reason: </b>Electrical maintenance and safety inspections.<br/><b>Reason: </b>Installation of new ventilation system.<br/><b>Reason: </b>Fire alarm testing and system updates.',
		},
		serviceWork: {
			workSteps:
				'Briefly describe the work steps to be performed, focusing on the tasks included in the work, such as: <br/><br/><b>Examples:</b><br/><br/><b>Replacing lighting in the shopping mall aisle: </b>Installing new lamps and fixtures in the ceiling.<br/><b>Installing ventilation in technical spaces: </b>Mounting ducts and fans for improved air circulation.<br/><b>Demolition and renovation of store areas: </b>Removing old walls, installing new partitions, and preparing for flooring.<br/><b>Painting and surface renovation in restaurants: </b>Spackling and painting walls, as well as replacing flooring materials.<br/><b>Installing signs on the facade: </b>Mounting new signs and advertising materials outdoors.<br/><b>Maintenance work in the garage: </b>Repairing lighting and ventilation systems.<br/><b>Servicing fire protection systems: </b>Testing and replacing sprinklers and smoke detectors.',
			workSteps_NL:
				'Briefly describe the work steps to be performed, focusing on the tasks included in the work, such as: <br/><br/><b>Examples:</b><br/><br/><b>Replacing lighting in the shopping mall aisle: </b>Installing new lamps and fixtures in the ceiling.<br/><b>Installing ventilation in technical spaces: </b>Mounting ducts and fans for improved air circulation.<br/><b>Demolition and renovation of store areas: </b>Removing old walls, installing new partitions, and preparing for flooring.<br/><b>Painting and surface renovation in restaurants: </b>Spackling and painting walls, as well as replacing flooring materials.<br/><b>Installing signs on the facade: </b>Mounting new signs and advertising materials outdoors.<br/><b>Maintenance work in the garage: </b>Repairing lighting and ventilation systems.<br/><b>Servicing fire protection systems: </b>Testing and replacing sprinklers and smoke detectors.',
			workArea:
				'Specify the area where the work will be carried out, such as: <br/><br/><b>Examples:</b><br/><br/><b>Shop: </b>Inside a store, including storage areas and display sections.<br/><b>Mall aisle: </b>Common areas between stores used by shoppers.<br/><b>Technology area: </b>Technical rooms for electrical and HVAC systems.<br/><b>Garage: </b>Parking facilities, including ramps and entrances.<br/><b>Service corridor: </b>Access passages for staff and deliveries.<br/><b>Restaurant: </b>Dining areas, kitchens, and serving zones.<br/><b>Outside facade: </b>Exterior walls and entrance areas.<br/><b>Square/Entrance: </b>Open spaces and main entrances to the shopping mall.',
			workArea_NL:
				'Specify the area where the work will be carried out, such as: <br/><br/><b>Examples:</b><br/><br/><b>Shop: </b>Inside a store, including storage areas and display sections.<br/><b>Mall aisle: </b>Common areas between stores used by shoppers.<br/><b>Technology area: </b>Technical rooms for electrical and HVAC systems.<br/><b>Garage: </b>Parking facilities, including ramps and entrances.<br/><b>Service corridor: </b>Access passages for staff and deliveries.<br/><b>Restaurant: </b>Dining areas, kitchens, and serving zones.<br/><b>Outside facade: </b>Exterior walls and entrance areas.<br/><b>Square/Entrance: </b>Open spaces and main entrances to the shopping mall.',
			numPeople:
				'Specify the total number of people who will be involved in the work, including workers, supervisors, and any subcontractors.',
			numPeople_NL:
				'Specify the total number of people who will be involved in the work, including workers, supervisors, and any subcontractors.',
			floor: 'Specify the floor where the work will be carried out in the shopping mall. Include additional details if needed, such as proximity to entrances or specific areas. <br/><br/><b>Examples:</b><br/><br/><b>Ground floor: </b>Near main entrances or stores.<br/><b>First floor: </b>In the restaurant area or additional shopping spaces.<br/><b>Basement: </b>In garages or technical rooms.<br/><b>Rooftop: </b>For ventilation or signage installations.',
			floor_NL:
				'Specify the floor where the work will be carried out in the shopping mall. Include additional details if needed, such as proximity to entrances or specific areas. <br/><br/><b>Examples:</b><br/><br/><b>Ground floor: </b>Near main entrances or stores.<br/><b>First floor: </b>In the restaurant area or additional shopping spaces.<br/><b>Basement: </b>In garages or technical rooms.<br/><b>Rooftop: </b>For ventilation or signage installations.',
			storeIdOrName:
				'Enter the Store ID if you have the number; otherwise, the store name is also acceptable. This information helps identify the exact location for planning and coordination. <br/><br/><b>Examples:</b><br/><br/><b>Store ID: </b>1023<br/><b>Name: </b>Gourmet Grill.',
			storeIdOrName_NL:
				'Enter the Store ID if you have the number; otherwise, the store name is also acceptable. This information helps identify the exact location for planning and coordination. <br/><br/><b>Examples:</b><br/><br/><b>Store ID: </b>1023<br/><b>Name: </b>Gourmet Grill.',
		},
	},
}
