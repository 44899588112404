type CountryCode = 'SE' | 'NL'

export const urw_country: CountryCode = 'SE'

export const swedishMalls = [
	'Mall of Scandinavia',
	'Nacka Forum',
	'Täby Centrum',
]
export const dutchMalls = [
	'Stadshart Amstelveen',
	'Stadshart Zoetermeer',
	'Mall of the Netherlands',
]

export const domains = {
	SE: 'https://arbetsberedning.urw.com/',
	NL: 'https://scmbeheer.nl/',
}

export const privacyPolicyUrls: { [key: string]: string } = {
	se: 'https://arbetsberedning.urw.com/',
	en: 'https://arbetsberedning.urw.com/',
	nl: 'https://arbetsberedning.urw.com/',
}

export const getCountrySuffix = (country: string) => {
	if (country === 'NL') {
		return '_NL'
	} else {
		return ''
	}
}

export const swedishRoles = [
	'SuperAdmin',
	'Admin - Alla centrum',
	'Admin - Westfield Mall of Scandinavia',
	'Admin - Nacka Forum',
	'Admin - Täby Centrum',
	'Observer - MOS',
	'Observer - Nacka Forum',
	'Observer - Täby Centrum',
	'Drift - Westfield Mall of Scandinavia',
	'Drift - Nacka Forum',
	'Drift - Täby Centrum',
	'Upprättare',
	'projectManager',
]

// ? Note: The role 'Drift' is names 'Operations' in Netherlands.
export const dutchRoles = [
	'Admin - All malls (NL)',
	'Admin - Stadshart Zoetermeer',
	'Admin - Stadshart Amstelveen',
	'Admin - Mall of the Netherlands',
	'Observer - Stadshart Zoetermeer',
	'Observer - Stadshart Amstelveen',
	'Observer - Mall of the Netherlands',
	'Operations - Stadshart Zoetermeer',
	'Operations - Stadshart Amstelveen',
	'Operations - Mall of the Netherlands',
	'Creator',
	'projectManager',
]

export function getRolesForCountry(country: 'SE' | 'NL'): string[] {
	if (country === 'SE') {
		return swedishRoles
	} else if (country === 'NL') {
		return dutchRoles
	}

	return swedishRoles // Default to swedish roles
}
