import { useField } from 'formik'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { SxProps, TextField, Tooltip, InputAdornment } from '@mui/material'

interface Props {
	label: string
	name: string
	type?: string
	sx?: SxProps
	placeholder?: string
	inputMode?: 'text' | 'numeric' | 'tel' | 'email'
	tooltip?: React.ReactNode | string
}

const CustomTextInput = ({
	label,
	name,
	sx,
	type = 'text',
	placeholder = '',
	inputMode = 'text',
	tooltip = '',
}: Props) => {
	const [field, meta] = useField({ name, placeholder, type })

	return (
		<TextField
			fullWidth
			name={name}
			type={type}
			label={label}
			value={meta.value}
			placeholder={placeholder}
			sx={sx}
			inputProps={{ inputMode }}
			onChange={field.onChange}
			onBlur={field.onBlur}
			error={meta.touched && Boolean(meta.error)}
			helperText={(meta.touched && meta.error) || ' '}
			InputLabelProps={{
				sx: tooltip ? { paddingRight: '24px' } : undefined,
			}}
			InputProps={{
				endAdornment: tooltip ? (
					<InputAdornment position="end">
						<Tooltip
							title={tooltip}
							arrow
							componentsProps={{
								tooltip: {
									sx: {
										bgcolor: 'white',
										color: 'rgba(0, 0, 0, 0.87)',
										boxShadow:
											'0 2px 10px rgba(0, 0, 0, 0.2)',
										border: '1px solid rgba(0, 0, 0, 0.1)',
										maxWidth: 500,
										padding: '10px',
										fontWeight: 'normal',
									},
								},
								arrow: {
									sx: {
										color: 'white',
									},
								},
							}}
						>
							<HelpOutlineIcon color="action" fontSize="small" />
						</Tooltip>
					</InputAdornment>
				) : null,
			}}
		/>
	)
}

export default CustomTextInput
