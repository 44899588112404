import { Box, FormGroup, Grid, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import CustomDivider from '../common/CustomDivider'
import CustomCheckboxInput from '../formik/CustomCheckboxInput'
import CustomFileInputWithTitle from '../formik/CustomFileInputWithTitle'
import CustomSwitchInput from '../formik/CustomSwitchInput'
import CustomTextInput from '../formik/CustomTextInput'
import CustomSelectFile from '../formik/CustomSelectFile'
import InfoText from '../common/InfoText'

interface Props {
	setSnackbarData: any
	isCreatedLoggedIn: boolean
}

const RequirementsForm = ({ setSnackbarData, isCreatedLoggedIn }: Props) => {
	const { values } = useFormikContext<any>()
	const { t } = useTranslation()

	const getFormattedTooltip = (key: string) => {
		return (
			<Typography component="div" sx={{ fontWeight: 'normal' }}>
				<Trans
					i18nKey={key}
					components={{
						b: <strong />,
						br: <br />,
					}}
				>
					{t(key)}
				</Trans>
			</Typography>
		)
	}

	return (
		<Grid container columnSpacing={4} rowSpacing={1}>
			<Grid item xs={12}>
				<CustomSwitchInput
					label={t('workPrep.hotWorkPermissionQuestion')}
					name="hotWorkPermission"
				/>
				<Typography
					fontWeight={300}
					fontSize={14}
					marginBottom={'10px'}
				>
					<Trans i18nKey="workPrep.hotWorkPermissionComment"></Trans>
				</Typography>
			</Grid>
			<CustomDivider />
			<Grid item xs={12}>
				<CustomSwitchInput
					label={t('workPrep.fireAlarmWillBeShutOffQuestion')}
					name="fireAlarmWillBeShutOff"
				/>
				<Typography
					fontWeight={300}
					fontSize={14}
					marginBottom={'10px'}
				>
					<Trans i18nKey="workPrep.fireAlarmWillBeShutOffComment"></Trans>
				</Typography>
			</Grid>
			<CustomDivider />
			<Grid item xs={12}>
				<CustomSwitchInput
					label={t('workPrep.sprinklerSystemWillBeShutOffQuestion')}
					name="sprinklerSystemWillBeShutOff"
					comment={
						<Typography fontWeight={300} fontSize={14}>
							<Trans i18nKey="workPrep.sprinklerSystemWillBeShutOffComment1" />
							<Box fontWeight={500} component={'span'}>
								<Trans i18nKey="workPrep.sprinklerSystemWillBeShutOffComment2" />
							</Box>
							<Trans i18nKey="workPrep.sprinklerSystemWillBeShutOffComment3" />
						</Typography>
					}
					sx={{ mb: '20px' }}
				/>
				{values.sprinklerSystemWillBeShutOff && (
					<CustomTextInput
						label={t('workPrep.duringWhatTimes')}
						name="whenWillSprinklerSystemBeShutOff"
						type="text"
						tooltip={getFormattedTooltip(
							'tooltips.workPrep.sprinklerSystemWillBeShutOffQuestion'
						)}
					/>
				)}
			</Grid>
			{values.sprinklerSystemWillBeShutOff &&
				values.fireAlarmWillBeShutOff && (
					<Grid item xs={12}>
						<InfoText
							sx={{
								borderColor: '#ef4444',
								backgroundColor: '#fef2f2',
								mb: '10px',
							}}
						>
							<Typography fontWeight={500} sx={{ mb: '10px' }}>
								{t('workPrep.fireAlarmAndSprinklerInfo.title')}
							</Typography>
							<Typography fontWeight={300}>
								Nacka forum -{' '}
								{t('workPrep.fireAlarmAndSprinklerInfo.nacka')}
							</Typography>
							<Typography fontWeight={300}>
								Täby centrum -{' '}
								{t('workPrep.fireAlarmAndSprinklerInfo.taby')}
							</Typography>
							<Typography fontWeight={300}>
								Mall of Scanidnavia -{' '}
								{t('workPrep.fireAlarmAndSprinklerInfo.mos')}
							</Typography>
						</InfoText>
					</Grid>
				)}
			<CustomDivider />
			<Grid item xs={12}>
				<CustomSwitchInput
					label={t('workPrep.ventilationWillBeShutOffQuestion')}
					name="ventilationWillBeShutOff"
					comment={
						<Typography fontWeight={300} fontSize={14}>
							<Trans i18nKey="workPrep.ventilationWillBeShutOffComment1" />
							<Box fontWeight={500} component={'span'}>
								<Trans i18nKey="workPrep.ventilationWillBeShutOffComment2" />
							</Box>
							<Trans i18nKey="workPrep.ventilationWillBeShutOffComment3" />
						</Typography>
					}
					sx={{ mb: '20px' }}
				/>
				{values.ventilationWillBeShutOff && (
					<CustomTextInput
						label={t('workPrep.duringWhatTimes')}
						name="whenWillVentilationBeShutOff"
						type="text"
						tooltip={getFormattedTooltip(
							'tooltips.workPrep.ventilationWillBeShutOffQuestion'
						)}
					/>
				)}
			</Grid>
			<CustomDivider />
			<Grid item xs={12}>
				<FormGroup>
					<Typography fontWeight={500}>
						{t('workPrep.additionalResources.title')}
					</Typography>

					<Typography fontWeight={300} fontSize={14}>
						{t('workPrep.additionalResources.comment')}
					</Typography>

					<CustomCheckboxInput
						label={t('workPrep.additionalResources.scissorLift')}
						name="scissorLift"
					/>

					<CustomCheckboxInput
						label={t('workPrep.additionalResources.skylift')}
						name="skylift"
					/>

					<CustomCheckboxInput
						label={t('workPrep.additionalResources.container')}
						name="container"
					/>

					<CustomCheckboxInput
						label={t(
							'workPrep.additionalResources.typeApprovedLadder'
						)}
						name="typeApprovedLadder"
					/>

					<CustomTextInput
						label={t(
							'workPrep.additionalResources.otherAdditionalResource'
						)}
						name="otherAdditionalResource"
						type="text"
						sx={{
							maxWidth: '300px',
						}}
					/>
				</FormGroup>
				<Typography fontWeight={500} mb={'10px'}>
					{t(
						'workPrep.additionalResources.attachLiftCardsForPersonalWorkingWithLifts'
					)}
				</Typography>
				{isCreatedLoggedIn ? (
					<>
						<Typography fontSize={14}>
							{t('workPrep.isLoggedInFileInfo')}
						</Typography>
						<CustomSelectFile
							translationLanguage={
								'workPrep.additionalResources.chooseLiftCards'
							}
							formAttribute={
								'liftCardsForPersonalWorkingWithLifts'
							}
							formValues={values}
							multiple
						/>
						<CustomFileInputWithTitle
							formAttribute={
								'liftCardsForPersonalWorkingWithLifts'
							}
							formValues={values}
							sx={{ mb: '10px' }}
							setSnackbar={setSnackbarData}
						/>
					</>
				) : (
					<CustomFileInputWithTitle
						formAttribute={'liftCardsForPersonalWorkingWithLifts'}
						formValues={values}
						sx={{ mb: '10px' }}
						setSnackbar={setSnackbarData}
					/>
				)}
			</Grid>
			<CustomDivider />
		</Grid>
	)
}

export default RequirementsForm
