import { Box, Grid, MenuItem, Typography } from '@mui/material'
import { useTranslation, Trans } from 'react-i18next'
import CustomDivider from '../common/CustomDivider'
import CustomDateInput from '../formik/CustomDateInput'
import CustomSelectInput from '../formik/CustomSelectInput'
import CustomTextAreaInput from '../formik/CustomTextAreaInput'
import CustomTextInput from '../formik/CustomTextInput'
import CustomTimeInput from '../formik/CustomTimeInput'
import { urw_country } from '../../constants'

const ServiceWorkInfo = () => {
	const { t } = useTranslation()

	const getFormattedTooltip = (key: string) => {
		return (
			<Typography component="div" sx={{ fontWeight: 'normal' }}>
				<Trans
					i18nKey={key}
					components={{
						b: <strong />,
						br: <br />,
					}}
				>
					{t(key)}
				</Trans>
			</Typography>
		)
	}

	const LocalMallMenuItems = () =>
		urw_country === 'NL' ? (
			<CustomSelectInput
				label={t('workPrep.relevantMall')}
				name="relevantMall"
				sx={{ width: '100%' }}
			>
				<MenuItem value="Stadshart Amstelveen">
					Stadshart Amstelveen
				</MenuItem>

				<MenuItem value="Mall of the Netherlands">
					Mall of the Netherlands
				</MenuItem>
			</CustomSelectInput>
		) : (
			<CustomSelectInput
				label={t('workPrep.relevantMall')}
				name="relevantMall"
				sx={{ width: '100%' }}
			>
				<MenuItem value="Westfield Mall of Scandinavia">
					Westfield Mall of Scandinavia
				</MenuItem>
				<MenuItem value="Täby Centrum">Westfield Täby Centrum</MenuItem>
				<MenuItem value="Nacka Forum">Nacka Forum</MenuItem>
			</CustomSelectInput>
		)

	return (
		<Grid container columnSpacing={4} rowSpacing={1}>
			<Grid item xs={12}>
				<CustomTextAreaInput
					label={t('workPrep.workSteps')}
					name="workSteps"
					type="text"
					tooltip={getFormattedTooltip(
						`tooltips.serviceWork.workSteps${
							urw_country === 'NL' ? '_NL' : ''
						}`
					)}
				/>
			</Grid>
			<Grid item xs={12} sm={8}>
				<CustomTextInput
					label={t('workPrep.workArea')}
					name="workArea"
					type="text"
					tooltip={getFormattedTooltip(
						`tooltips.serviceWork.workArea${
							urw_country === 'NL' ? '_NL' : ''
						}`
					)}
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				{LocalMallMenuItems()}
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomTextInput
					label={t('workPrep.numPeople')}
					name="numPeople"
					type="text"
					inputMode="numeric"
					tooltip={getFormattedTooltip(
						`tooltips.serviceWork.numPeople${
							urw_country === 'NL' ? '_NL' : ''
						}`
					)}
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomTextInput
					label={t('workPrep.floor')}
					name="floor"
					type="text"
					tooltip={getFormattedTooltip(
						`tooltips.serviceWork.floor${
							urw_country === 'NL' ? '_NL' : ''
						}`
					)}
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomTextInput
					label={t('workPrep.storeIdOrName')}
					name="storeIdOrName"
					type="text"
					tooltip={getFormattedTooltip(
						`tooltips.serviceWork.storeIdOrName${
							urw_country === 'NL' ? '_NL' : ''
						}`
					)}
				/>
			</Grid>
			<CustomDivider />
			<Grid item xs={12} sm={4}>
				<CustomDateInput
					label={t('workPrep.startDate')}
					name="startDate"
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomDateInput label={t('workPrep.endDate')} name="endDate" />
			</Grid>
			<Box
				component={Grid}
				item
				display={{ xs: 'none', sm: 'block' }}
				xs={0}
				sm={4}
			/>
			<Grid item xs={12} sm={4}>
				<CustomTimeInput
					label={t('workPrep.startingTime')}
					name="startingTime"
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomTimeInput
					label={t('workPrep.endingTime')}
					name="endingTime"
				/>
			</Grid>
			<Box
				component={Grid}
				item
				display={{ xs: 'none', sm: 'block' }}
				xs={0}
				sm={4}
			/>
			<CustomDivider />
		</Grid>
	)
}

export default ServiceWorkInfo
