import { gql } from '@apollo/client'

export const SUBMIT_WORK_PREP = gql`
	mutation SubmitWorkPrep(
		$workPrepAnswers: String!
		$workPrepPDF: Upload!
		$workWithWaterCertificate: [Upload]
		$electricalWorkCertificate: [Upload]
		$constructionEnvironmentWorkPlan: [Upload]
		$baseUCertificate: [Upload]
		$basePCertificate: [Upload]
		$willTheWorkTakeLongerThan30DaysCertificate: [Upload]
		$scheduleForTheProject: [Upload]
		$liftCardsForPersonalWorkingWithLifts: [Upload]
		$workWithWaterCertificateUserDocumentID: [String]
		$electricalWorkCertificateUserDocumentID: [String]
		$baseUCertificateUserDocumentID: [String]
		$basePCertificateUserDocumentID: [String]
		$liftCardsForPersonalWorkingWithLiftsUserDocumentID: [String]
		$selectedUserDocuments: String!
	) {
		submitWorkPrep(
			workPrepAnswers: $workPrepAnswers
			workPrepPDF: $workPrepPDF
			workWithWaterCertificate: $workWithWaterCertificate
			electricalWorkCertificate: $electricalWorkCertificate
			constructionEnvironmentWorkPlan: $constructionEnvironmentWorkPlan
			baseUCertificate: $baseUCertificate
			basePCertificate: $basePCertificate
			willTheWorkTakeLongerThan30DaysCertificate: $willTheWorkTakeLongerThan30DaysCertificate
			scheduleForTheProject: $scheduleForTheProject
			liftCardsForPersonalWorkingWithLifts: $liftCardsForPersonalWorkingWithLifts
			workWithWaterCertificateUserDocumentID: $workWithWaterCertificateUserDocumentID
			electricalWorkCertificateUserDocumentID: $electricalWorkCertificateUserDocumentID
			baseUCertificateUserDocumentID: $baseUCertificateUserDocumentID
			basePCertificateUserDocumentID: $basePCertificateUserDocumentID
			liftCardsForPersonalWorkingWithLiftsUserDocumentID: $liftCardsForPersonalWorkingWithLiftsUserDocumentID
			selectedUserDocuments: $selectedUserDocuments
		)
	}
`

export const SUBMIT_SERVICE_WORK = gql`
	mutation SubmitServiceWork(
		$serviceWorkAnswers: String!
		$serviceWorkPDF: Upload!
	) {
		submitServiceWork(
			serviceWorkAnswers: $serviceWorkAnswers
			serviceWorkPDF: $serviceWorkPDF
		)
	}
`

export const UPDATE_SERVICE_WORK = gql`
	mutation UpdateServiceWork(
		$serviceWorkAnswers: String!
		$serviceWorkPDF: Upload!
		$jwt: String!
	) {
		updateServiceWork(
			serviceWorkAnswers: $serviceWorkAnswers
			serviceWorkPDF: $serviceWorkPDF
			jwt: $jwt
		)
	}
`

export const UPDATE_SERVICE_WORK_STATUS = gql`
	mutation UpdateServiceWorkStatus(
		$serviceWorkId: String!
		$userEmail: String!
		$newStatus: String!
		$newStatusChangedOn: String!
		$message: String!
		$serviceWorkPDF: Upload!
		$jwt: String!
	) {
		updateServiceWorkStatus(
			serviceWorkId: $serviceWorkId
			userEmail: $userEmail
			newStatus: $newStatus
			newStatusChangedOn: $newStatusChangedOn
			message: $message
			serviceWorkPDF: $serviceWorkPDF
			jwt: $jwt
		)
	}
`

export const SUBMIT_FIRE_ALARM_SHUTDOWN = gql`
	mutation SubmitFireAlarmShutdown(
		$fireAlarmAnswers: String!
		$fireAlarmPDF: Upload!
	) {
		submitFireAlarmShutdown(
			fireAlarmAnswers: $fireAlarmAnswers
			fireAlarmPDF: $fireAlarmPDF
		)
	}
`

export const UPDATE_FIRE_ALARM = gql`
	mutation updateFireAlarm(
		$fireAlarmAnswers: String!
		$fireAlarmPDF: Upload!
		$isCompleteRequest: Boolean!
		$jwt: String!
	) {
		updateFireAlarm(
			fireAlarmAnswers: $fireAlarmAnswers
			fireAlarmPDF: $fireAlarmPDF
			isCompleteRequest: $isCompleteRequest
			jwt: $jwt
		)
	}
`

export const UPDATE_FIRE_ALARM_STATUS = gql`
	mutation UpdateFireAlarmStatus(
		$fireAlarmId: String!
		$userEmail: String!
		$newStatus: String!
		$newStatusChangedOn: String!
		$message: String!
		$fireAlarmPDF: Upload!
		$jwt: String!
	) {
		updateFireAlarmStatus(
			fireAlarmId: $fireAlarmId
			userEmail: $userEmail
			newStatus: $newStatus
			newStatusChangedOn: $newStatusChangedOn
			message: $message
			fireAlarmPDF: $fireAlarmPDF
			jwt: $jwt
		)
	}
`

export const DELETE_FIRE_ALARM = gql`
	mutation DeleteFireAlarm($fireAlarmId: String!, $jwt: String!) {
		deleteFireAlarm(fireAlarmId: $fireAlarmId, jwt: $jwt)
	}
`

export const DELETE_SERVICE_WORK = gql`
	mutation DeleteServiceWork($serviceWorkId: String!, $jwt: String!) {
		deleteServiceWork(serviceWorkId: $serviceWorkId, jwt: $jwt)
	}
`

export const UPDATE_WORK_PREP_STATUS = gql`
	mutation UpdateWorkPrepStatus(
		$workPrepId: String!
		$userEmail: String!
		$newStatus: String!
		$newStatusChangedOn: String!
		$message: String!
		$workPrepPDF: Upload!
		$jwt: String!
	) {
		updateWorkPrepStatus(
			workPrepId: $workPrepId
			userEmail: $userEmail
			newStatus: $newStatus
			newStatusChangedOn: $newStatusChangedOn
			message: $message
			workPrepPDF: $workPrepPDF
			jwt: $jwt
		)
	}
`

export const SIGN_PROJECT_MANAGER = gql`
	mutation SignProjectManager($workPrepId: String!, $jwt: String!) {
		signProjectManager(workPrepId: $workPrepId, jwt: $jwt)
	}
`

export const SEND_COMPLETION_REQUEST = gql`
	mutation SendCompletionRequest($completionRequest: String!) {
		sendCompletionRequest(completionRequest: $completionRequest)
	}
`

export const SEND_COMPLETION_REQUEST_SERVICE_WORK = gql`
	mutation SendCompletionRequestServiceWork($completionRequest: String!) {
		sendCompletionRequestServiceWork(completionRequest: $completionRequest)
	}
`

export const SEND_COMPLETION_REQUEST_FIRE_ALARM = gql`
	mutation SendCompletionRequestFireAlarm(
		$completionRequest: String!
		$jwt: String!
	) {
		sendCompletionRequestFireAlarm(
			completionRequest: $completionRequest
			jwt: $jwt
		)
	}
`

export const ADD_COMMENT = gql`
	mutation addComment($comment: String!) {
		addComment(comment: $comment)
	}
`

export const REMOVE_COMMENT = gql`
	mutation removeComment($workPrepId: String!, $commentId: String!) {
		removeComment(workPrepId: $workPrepId, commentId: $commentId)
	}
`

export const ADD_COMMENT_FIRE_ALARM = gql`
	mutation addCommentFireAlarm($comment: String!, $jwt: String!) {
		addCommentFireAlarm(comment: $comment, jwt: $jwt)
	}
`

export const REMOVE_COMMENT_FIRE_ALARM = gql`
	mutation removeCommentFireAlarm(
		$fireAlarmId: String!
		$commentId: String!
	) {
		removeCommentFireAlarm(fireAlarmId: $fireAlarmId, commentId: $commentId)
	}
`

export const DELETE_WORK_PREP = gql`
	mutation DeleteWorkPrep($workPrepId: String!, $jwt: String!) {
		deleteWorkPrep(workPrepId: $workPrepId, jwt: $jwt)
	}
`
export const DELETE_USER_FROM_WORK_PREP = gql`
	mutation DeleteUserFromWorkPrep($workPrepId: String!, $jwt: String!) {
		deleteUserFromWorkPrep(workPrepId: $workPrepId, jwt: $jwt)
	}
`

export const EDIT_WORK_PREP = gql`
	mutation EditWorkPrep(
		$workPrep: String!
		$workPrepPDF: Upload!
		$preventNewSignatures: Boolean
		$workWithWaterCertificate: [Upload]
		$electricalWorkCertificate: [Upload]
		$constructionEnvironmentWorkPlan: [Upload]
		$baseUCertificate: [Upload]
		$basePCertificate: [Upload]
		$willTheWorkTakeLongerThan30DaysCertificate: [Upload]
		$scheduleForTheProject: [Upload]
		$liftCardsForPersonalWorkingWithLifts: [Upload]
		$deletedFiles: [String]!
		$jwt: String!
	) {
		editWorkPrep(
			workPrep: $workPrep
			workPrepPDF: $workPrepPDF
			preventNewSignatures: $preventNewSignatures
			workWithWaterCertificate: $workWithWaterCertificate
			electricalWorkCertificate: $electricalWorkCertificate
			constructionEnvironmentWorkPlan: $constructionEnvironmentWorkPlan
			baseUCertificate: $baseUCertificate
			basePCertificate: $basePCertificate
			willTheWorkTakeLongerThan30DaysCertificate: $willTheWorkTakeLongerThan30DaysCertificate
			scheduleForTheProject: $scheduleForTheProject
			liftCardsForPersonalWorkingWithLifts: $liftCardsForPersonalWorkingWithLifts
			deletedFiles: $deletedFiles
			jwt: $jwt
		)
	}
`

export const EDIT_ENDDATE_WORK_PREP = gql`
	mutation EditEndDateWorkPrep($workPrepId: String!, $endDate: String!) {
		editEndDateWorkPrep(workPrepId: $workPrepId, endDate: $endDate)
	}
`

export const REQUEST_NEW_SIGNATURES = gql`
	mutation requestNewSignatures(
		$workPrepId: String!
		$isBasUUpdated: Boolean
		$isBasPUpdated: Boolean
		$isConstructionEnvironmentWorkPlanUpdated: Boolean!
		$workPrepPDF: Upload!
		$jwt: String!
		$isCompleteRequest: Boolean
	) {
		requestNewSignatures(
			workPrepId: $workPrepId
			isBasUUpdated: $isBasUUpdated
			isBasPUpdated: $isBasPUpdated
			isConstructionEnvironmentWorkPlanUpdated: $isConstructionEnvironmentWorkPlanUpdated
			workPrepPDF: $workPrepPDF
			jwt: $jwt
			isCompleteRequest: $isCompleteRequest
		)
	}
`
export const SEND_REMINDER = gql`
	mutation sendReminder($workPrepId: String!, $jwt: String!) {
		sendReminder(workPrepId: $workPrepId, jwt: $jwt)
	}
`

export const SEND_REMINDER_FIRE_ALARM = gql`
	mutation sendReminderFireAlarm($fireAlarmId: String!, $jwt: String!) {
		sendReminderFireAlarm(fireAlarmId: $fireAlarmId, jwt: $jwt)
	}
`

export const SEND_REMINDER_SERVICE_WORK = gql`
	mutation sendReminderServiceWork($serviceWorkId: String!, $jwt: String!) {
		sendReminderServiceWork(serviceWorkId: $serviceWorkId, jwt: $jwt)
	}
`
