import { Grid, Typography, Tooltip, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PrettyDateWithTime } from '../../common/PrettyDate'
import { WorkPrepAnswers_NL } from '../../../types'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PendingIcon from '@mui/icons-material/Pending'
import ErrorIcon from '@mui/icons-material/Error'

interface Props {
	workPrep: WorkPrepAnswers_NL
	className?: string
}

const headerText = {
	fontWeight: 'medium',
	fontSize: 14,
}
const infoText = {
	fontSize: 14,
	color: 'gray',
}

const EmailDeliveryStatus = ({
	status,
	isSigned,
}: {
	status: string
	isSigned: boolean
}): JSX.Element => {
	const { t } = useTranslation()

	return (
		<>
			{status === 'pending' && !isSigned && (
				<Tooltip
					title={t('workPrepDetails.notificationStatus.pending')}
				>
					<PendingIcon
						fontSize="inherit"
						sx={{ marginLeft: '4px', color: '#444444' }}
					/>
				</Tooltip>
			)}

			{(status === 'delivered' || isSigned) && (
				<Tooltip
					title={t('workPrepDetails.notificationStatus.delivered')}
				>
					<CheckCircleIcon
						fontSize="inherit"
						color="success"
						sx={{ marginLeft: '4px' }}
					/>
				</Tooltip>
			)}

			{status === 'undeliverable' && (
				<Tooltip
					title={t(
						'workPrepDetails.notificationStatus.undeliverable'
					)}
				>
					<ErrorIcon
						fontSize="inherit"
						color="error"
						sx={{ marginLeft: '4px' }}
					/>
				</Tooltip>
			)}
		</>
	)
}

const generateListItems: (
	workPrep: WorkPrepAnswers_NL,
	t: any
) => JSX.Element[] = (workPrep, t) => {
	const returnList: JSX.Element[] = []
	returnList.push(
		<Grid item width={'200px'} marginBottom={'50px'} key={'1'}>
			<Grid container direction={'column'} rowSpacing={1}>
				<Grid item>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'flex-start',
						}}
					>
						<Typography sx={headerText}>
							{t('workPrep.createdBy')}
						</Typography>
						<EmailDeliveryStatus
							status={
								workPrep.creatorOfWorkPrep.notificationStatus
							}
							isSigned={
								workPrep.creatorOfWorkPrep.signStatus ===
								'Signed'
							}
						/>
					</Box>
					<Typography sx={infoText}>
						{workPrep.creatorOfWorkPrep.name}
					</Typography>
				</Grid>
				<Grid item>
					<Typography sx={headerText}>
						{t('workPrep.status')}
					</Typography>
					<Typography sx={infoText}>
						{workPrep.creatorOfWorkPrep.signStatus || '-'}
					</Typography>
				</Grid>
				<Grid item>
					<Typography sx={headerText}>
						{t('workPrep.date')}
					</Typography>
					<Typography sx={infoText}>
						{workPrep.creatorOfWorkPrep.signStatusChangedOn ? (
							<PrettyDateWithTime
								dateString={
									workPrep.creatorOfWorkPrep
										.signStatusChangedOn
								}
							/>
						) : (
							'-'
						)}
					</Typography>
				</Grid>
				<Grid item>
					<Typography sx={headerText}>
						{t('workPrep.signatureType')}
					</Typography>
					<Typography sx={infoText}>
						{workPrep.creatorOfWorkPrep.signatureType
							? t(
									'workPrepDetails.' +
										workPrep.creatorOfWorkPrep.signatureType
							  )
							: '-'}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	)
	if (workPrep.projectManager?.exists) {
		returnList.push(
			<Grid item width={'200px'} marginBottom={'50px'} key={'2'}>
				<Grid container direction={'column'} rowSpacing={1}>
					<Grid item>
						<Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
							<Typography sx={headerText}>
								{t('workPrep.projectManager')}
							</Typography>
							<EmailDeliveryStatus
								status={
									workPrep.projectManager.notificationStatus
								}
								isSigned={
									workPrep.projectManager.signStatus ===
									'Signed'
								}
							/>
						</Box>
						<Typography sx={infoText}>
							{workPrep.projectManager.name || '-'}
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={headerText}>
							{t('workPrep.status')}
						</Typography>
						<Typography sx={infoText}>
							{workPrep.projectManager.signStatus || '-'}
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={headerText}>
							{t('workPrep.date')}
						</Typography>
						<Typography sx={infoText}>
							{workPrep.projectManager.signStatusChangedOn ? (
								<PrettyDateWithTime
									dateString={
										workPrep.projectManager
											.signStatusChangedOn
									}
								/>
							) : (
								'-'
							)}
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={headerText}>
							{t('workPrep.signatureType')}
						</Typography>
						<Typography sx={infoText}>
							{workPrep.projectManager.signatureType
								? t(
										'workPrepDetails.' +
											workPrep.projectManager
												.signatureType
								  )
								: '-'}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		)
	}

	returnList.push(
		<Grid item width={'200px'} marginBottom={'50px'} key={'5'}>
			<Grid container direction={'column'} rowSpacing={1}>
				<Grid item>
					<Typography sx={headerText}>
						{t('workPrep.management')}
					</Typography>
					<Typography sx={infoText}>
						{workPrep.statusChangedBy || '-'}
					</Typography>
				</Grid>
				<Grid item>
					<Typography sx={headerText}>
						{t('workPrep.status')}
					</Typography>
					<Typography sx={infoText}>
						{workPrep.status || '-'}
					</Typography>
				</Grid>
				<Grid item>
					<Typography sx={headerText}>
						{t('workPrep.date')}
					</Typography>
					<Typography sx={infoText}>
						{workPrep.statusChangedOn ? (
							<PrettyDateWithTime
								dateString={workPrep.statusChangedOn}
							/>
						) : (
							'-'
						)}
					</Typography>
				</Grid>
				<Grid item>
					<Typography sx={headerText}>
						{t('workPrep.signatureType')}
					</Typography>
					<Typography sx={infoText}>
						{workPrep.status
							? t('workPrepDetails.AdminPanel')
							: '-'}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	)
	return returnList
}
const SignStatusList_NL = ({ workPrep, className = '' }: Props) => {
	const { t } = useTranslation()

	return (
		<Grid container paddingLeft={'20px'} marginY={2} columnSpacing={10}>
			{generateListItems(workPrep, t)}
		</Grid>
	)
}

export default SignStatusList_NL
