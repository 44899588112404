import { Formik, Form } from 'formik'
import {
	Alert,
	Button,
	Snackbar,
	Typography,
	Stepper,
	Step,
	StepLabel,
	Grid,
	CircularProgress,
} from '@mui/material'
import * as Yup from 'yup'
import { useState } from 'react'
import {
	FireAlarmAnswers,
	ContactPersonsOnSite,
	LocationState,
	SnackbarData,
} from '../types'
import { useMutation } from '@apollo/client'
import { SUBMIT_FIRE_ALARM_SHUTDOWN } from '../graphql/mutations/workPreps'
import { Trans, useTranslation } from 'react-i18next'
import ArrowForward from '@mui/icons-material/ArrowForward'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { pdf } from '@react-pdf/renderer'
import { useLocation, useParams } from 'react-router-dom'
import FireAlarmPDF from '../components/admin/pdf/FireAlarmPDF'
import FireAlarmScope from '../components/forms/FireAlarmScope'
import FireAlarmSign from '../components/forms/FireAlarmSign'
import FireAlarmSignStatus from '../components/forms/FireAlarmSignStatus'
import { ScrollToError } from '../components/formik/ScrollToError'
import PaperLooksContainer from '../components/common/PaperLooksContainer'
import { urw_country } from '../constants'

const FireAlarmForm = () => {
	const location = useLocation()
	const params = useParams()
	const state = location.state as LocationState
	const { t } = useTranslation()
	const [submitFireAlarmShutdown] = useMutation(SUBMIT_FIRE_ALARM_SHUTDOWN)
	const [stepperStep, setStepperStep] = useState<number>(0)

	const [snackbarData, setSnackbarData] = useState<SnackbarData>({
		message: 'This is a success message!',
		severity: 'success',
		open: false,
	})

	const isCreatedLoggedIn = state && state.user ? true : false

	const isFirstStep = stepperStep === 0
	const isLastStep = stepperStep === 1

	const closeSnackbarHandler = () => {
		const tempSnackbarData = { ...snackbarData }
		tempSnackbarData.open = false
		setSnackbarData(tempSnackbarData)
	}

	const today = new Date()
	today.setHours(0, 0, 0, 0)

	const workPrepValidationSchema = [
		Yup.object({
			fireAlarmOrHotWork: Yup.string().required(t('common.required')),
			workArea: Yup.string().required(t('common.required')),
			shutdownReason: Yup.string().required(t('common.required')),
			startDate: Yup.date()
				.typeError(t('common.invalidDate'))
				.required(t('common.required'))
				.min(today, t('common.beforeToday')),
			endDate: Yup.date()
				.typeError(t('common.invalidDate'))
				.required(t('common.required'))
				.min(Yup.ref('startDate'), t('common.beforeStart')),
			weekdays: Yup.boolean(),
			weekends: Yup.boolean(),
			aroundTheClock: Yup.boolean(),
			weekdayStartingTime: Yup.date().when('weekdays', {
				is: true,
				then: Yup.date()
					.typeError(t('common.invalidTime'))
					.required(t('common.required')),
			}),
			weekdayEndingTime: Yup.date().when('weekdays', {
				is: true,
				then: Yup.date()
					.typeError(t('common.invalidTime'))
					.required(t('common.required')),
			}),
			weekendStartingTime: Yup.date().when('weekends', {
				is: true,
				then: Yup.date()
					.typeError(t('common.invalidTime'))
					.required(t('common.required')),
			}),
			weekendEndingTime: Yup.date().when('weekends', {
				is: true,
				then: Yup.date()
					.typeError(t('common.invalidTime'))
					.required(t('common.required')),
			}),
		}).test('at-least-one', '', (values) => {
			const { weekdays, weekends, aroundTheClock } = values
			if (!weekdays && !weekends && !aroundTheClock) {
				return new Yup.ValidationError(
					t('common.atLeastOne'),
					null,
					'checkboxes'
				)
			}
			return true
		}),
		Yup.object({
			createdByCompany: Yup.string().required(t('common.required')),
			createdByPersonName: Yup.string().required(t('common.required')),
			telephoneNumberToCreator: Yup.string().required(
				t('common.required')
			),
			emailToCreator: Yup.string()
				.email(t('common.invalidEmailAddress'))
				.required(t('common.required')),
			confirmPrivacyPolicy: Yup.bool().oneOf(
				[true],
				t('common.required')
			),
		}),
	]

	const currentValidationSchema = workPrepValidationSchema[stepperStep]

	const initialFormikValues = {
		fireAlarmOrHotWork: '',
		workArea: '',
		shutdownReason: '',
		startDate: '',
		endDate: '',
		weekdayStartingTime: '',
		weekdayEndingTime: '',
		weekendStartingTime: '',
		weekendEndingTime: '',
		weekdays: false,
		weekends: false,
		aroundTheClock: false,
		createdByCompany: '',
		createdByPersonName: '',
		telephoneNumberToCreator: '',
		emailToCreator: '',
		nameContactPerson1: '',
		emailContactPerson1: '',
		companyContactPerson1: '',
		numberContactPerson1: '',
		nameContactPerson2: '',
		emailContactPerson2: '',
		companyContactPerson2: '',
		numberContactPerson2: '',
		numberOfContactPersons: 0,
	}

	const backButtonClicked = () => {
		setStepperStep(stepperStep - 1)
	}

	const handleSubmit = async (values: any, actions: any) => {
		if (isLastStep) {
			await fireAlarmSubmit(values, actions.resetForm)
		} else {
			setStepperStep(stepperStep + 1)
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			})
			actions.setTouched({})
		}
		actions.setSubmitting(false)
	}

	const parseTime = (values: any) => {
		const timeTypes = [
			'weekdayStartingTime',
			'weekdayEndingTime',
			'weekendStartingTime',
			'weekendEndingTime',
		]
		const parsedTimes: any = {}
		for (const type of timeTypes) {
			if (values[type]) {
				let hours = `${values[type].getHours()}`
				let minutes = `${values[type].getMinutes()}`
				if (parseInt(hours) < 10) {
					hours = `0${hours}`
				}
				if (parseInt(minutes) < 10) {
					minutes = `0${minutes}`
				}
				parsedTimes[type] = `${hours}:${minutes}`
			} else {
				parsedTimes[type] = ''
			}
		}
		return parsedTimes
	}

	const fireAlarmSubmit = async (values: any, resetForm: any) => {
		const workingHours = parseTime(values)

		const contactPersonsOnSite: ContactPersonsOnSite = {}

		if (values.numberOfContactPersons > 0) {
			contactPersonsOnSite['contactPerson1'] = {
				name: values.nameContactPerson1,
				email: values.emailContactPerson1.toLowerCase(),
				number: values.numberContactPerson1,
				company: values.companyContactPerson1,
			}
		}
		if (values.numberOfContactPersons > 1) {
			contactPersonsOnSite['contactPerson2'] = {
				name: values.nameContactPerson2,
				email: values.emailContactPerson2.toLowerCase(),
				number: values.numberContactPerson2,
				company: values.companyContactPerson2,
			}
		}

		const fireAlarmAnswers: FireAlarmAnswers = {
			fireAlarmOrHotWork: values.fireAlarmOrHotWork,
			workArea: values.workArea,
			shutdownReason: values.shutdownReason,
			id: '',
			urw_country: urw_country,
			workPrepId: state.id!,
			workPrepInfo: {
				floor: '',
				storeIdOrName: '',
				workArea: '',
			},
			status: '',
			relevantMall: state.relevantMall!,
			startDate: values.startDate!,
			endDate: values.endDate!,
			establishedDate: new Date(),
			hours: workingHours,
			weekdays: values.weekdays,
			weekends: values.weekends,
			aroundTheClock: values.aroundTheClock,
			createdByCompany: values.createdByCompany,
			createdByPersonName: values.createdByPersonName,
			telephoneNumberToCreator: values.telephoneNumberToCreator,
			emailToCreator: values.emailToCreator.toLowerCase(),
			signStatus: '',
			signStatusChangedOn: '',
			signatureType: '',
			statusChangedBy: '',
			statusChangedOn: '',
			contactPersonsOnSite: contactPersonsOnSite,
			isCreatedLoggedIn: {
				answer: isCreatedLoggedIn,
				email: isCreatedLoggedIn ? state.user! : '',
			},
		}

		setSnackbarData({
			message: t('fireAlarmShutdown.form.submittingForm'),
			severity: 'info',
			open: true,
		})

		const fireAlarmBlob = await pdf(
			<FireAlarmPDF fireAlarmAnswers={fireAlarmAnswers} customLang="sv" />
		).toBlob()

		const fireAlarmPDF = new File([fireAlarmBlob], 'filename.pdf')

		setSnackbarData({
			message: t('fireAlarmShutdown.form.uploadingData'),
			severity: 'info',
			open: true,
		})

		const res = await submitFireAlarmShutdown({
			variables: {
				fireAlarmAnswers: JSON.stringify(fireAlarmAnswers),
				fireAlarmPDF,
			},
		})

		if (res.data.submitFireAlarmShutdown == 'Assently error') {
			setSnackbarData({
				message: t('fireAlarmShutdown.form.assentlyError'),
				severity: 'error',
				open: true,
			})
		} else {
			setSnackbarData({
				message: t('fireAlarmShutdown.form.submittedForm'),
				severity: 'success',
				open: true,
			})
			resetForm()
			try {
				const redirectUrl = new URL(res.data.submitFireAlarmShutdown)
				window.location.replace(redirectUrl)
			} catch {
				setSnackbarData({
					message: t('workPrep.urlError'),
					severity: 'error',
					open: true,
				})
			}
		}
	}

	function _renderStepContent(step: number) {
		switch (step) {
			case 0:
				return <FireAlarmScope />
			case 1:
				return <FireAlarmSign />
			default:
				return <div>Not Found</div>
		}
	}

	const steps = [
		t('fireAlarmShutdown.form.stepper.scope'),
		t('fireAlarmShutdown.form.stepper.sign'),
	]

	if (params.signStatus) {
		return (
			<>
				<PaperLooksContainer backBtnPath="/">
					<Grid
						container
						paddingX={{ xs: 0, sm: 5 }}
						paddingY={5}
						direction={'column'}
					>
						<Grid
							item
							xs={12}
							marginTop={'30px'}
							marginBottom={'30px'}
						>
							<Stepper
								activeStep={
									params.signStatus === 'completed' ? 2 : 1
								}
								alternativeLabel
							>
								{steps.map((label) => (
									<Step key={label}>
										<StepLabel>{label}</StepLabel>
									</Step>
								))}
							</Stepper>
						</Grid>
						<FireAlarmSignStatus
							signStatus={params.signStatus}
							setSnackbarData={setSnackbarData}
						/>
					</Grid>
				</PaperLooksContainer>
				<Snackbar
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					open={snackbarData.open}
					onClose={closeSnackbarHandler}
					key={'bottom center'}
				>
					<Alert
						onClose={closeSnackbarHandler}
						severity={snackbarData.severity}
						sx={{ width: '100%' }}
					>
						{snackbarData.message}
					</Alert>
				</Snackbar>
			</>
		)
	}

	if (!state) {
		return (
			<PaperLooksContainer backBtnPath="/">
				<Typography textAlign={'center'} marginY={5}>
					{t('fireAlarmShutdown.noAccess')}
				</Typography>
			</PaperLooksContainer>
		)
	}

	return (
		<>
			<PaperLooksContainer backBtnPath="/" disabled={!isFirstStep} cancel>
				<Grid
					container
					paddingX={{ xs: 0, sm: 5 }}
					paddingY={5}
					direction={'column'}
				>
					<Grid item xs={12}>
						<Typography
							fontWeight={400}
							fontSize={25}
							marginBottom={'10px'}
						>
							<Trans i18nKey="fireAlarmShutdown.form.title"></Trans>
							{isCreatedLoggedIn ? ': ' + state.user! : ''}
						</Typography>
						<Typography
							fontWeight={300}
							fontSize={18}
							marginBottom={'10px'}
						>
							<Trans i18nKey="fireAlarmShutdown.form.applies"></Trans>
							{state.id}
						</Typography>
					</Grid>
					<Grid item xs={12} marginTop={'30px'} marginBottom={'30px'}>
						<Stepper activeStep={stepperStep} alternativeLabel>
							{steps.map((label) => (
								<Step key={label}>
									<StepLabel>{label}</StepLabel>
								</Step>
							))}
						</Stepper>
					</Grid>
					<Formik
						initialValues={initialFormikValues}
						validationSchema={currentValidationSchema}
						onSubmit={handleSubmit}
					>
						{({ isSubmitting }) => (
							<Form id={'2'}>
								<ScrollToError />
								{_renderStepContent(stepperStep)}
								{stepperStep < 2 && (
									<Grid
										container
										justifyContent="space-between"
										alignItems="center"
										marginTop={'20px'}
									>
										<Grid item>
											<Button
												variant="outlined"
												onClick={backButtonClicked}
												disabled={stepperStep === 0}
												startIcon={<ArrowBack />}
												sx={{ width: 150, height: 50 }}
											>
												{t('workPrep.back')}
											</Button>
										</Grid>

										<Grid item>
											<Button
												disabled={isSubmitting}
												type="submit"
												variant="contained"
												endIcon={
													isSubmitting ? (
														<CircularProgress
															size={20}
														/>
													) : (
														<ArrowForward />
													)
												}
												sx={{ width: 150, height: 50 }}
											>
												{isLastStep
													? t('workPrep.sign')
													: t('workPrep.continue')}
											</Button>
										</Grid>
									</Grid>
								)}
							</Form>
						)}
					</Formik>
				</Grid>
			</PaperLooksContainer>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={snackbarData.open}
				onClose={closeSnackbarHandler}
				key={'bottom center'}
			>
				<Alert
					onClose={closeSnackbarHandler}
					severity={snackbarData.severity}
					sx={{ width: '100%' }}
				>
					{/* Show loading animation on text if form is being uploaded */}
					{snackbarData.message ===
					t('fireAlarmShutdown.form.uploadingData') ? (
						<span className="loading-dots">
							{snackbarData.message}
						</span>
					) : (
						snackbarData.message
					)}
				</Alert>
			</Snackbar>
		</>
	)
}

export default FireAlarmForm
