export const NL_TRANSLATIONS = {
	homePage: {
		welcomeTitle:
			'Welkom bij de werkvoorbereidingswebsite van Unibail Rodamco Westfield!',
		welcomeTitle1: 'Welkom bij',
		welcomeTitle2: "Unibail Rodamco Westfield's",
		welcomeTitle3: 'werkvoorbereidingswebsite!',
		fillOutFireAlarmShutdownBtnText:
			'Vul brandalarm uitschakelen & heet werk in',
		fillOutWorkPrepBtnText: 'Vul werkvoorbereiding in',
		fillOutServiceWorkBtnText: 'Vul servicewerk in',
		adminPanelBtnText: 'Beheerpaneel',
		createUserBtnText: 'Gebruiker aanmaken',
		loginBtnText: 'Inloggen',
		logoutBtnText: 'Uitloggen',
	},
	createUser: {
		title: 'Gebruiker aanmaken',
		form: {
			name: 'Naam',
			email: 'E-mail',
			password: 'Wachtwoord',
			minPasswordLength:
				'Het wachtwoord moet minimaal zes tekens lang zijn',
			confirmPassword: 'Bevestig wachtwoord',
			chooseARole: 'Kies een rol',
			role: 'Rol',
			submitBtnText: 'Gebruiker aanmaken',
		},
		creatingUser: 'Gebruiker aanmaken...',
		userCreated:
			'Gebruiker aangemaakt! Er is een e-mail gestuurd naar het opgegeven e-mailadres.',
		error: 'Er is iets misgegaan...',
		passwordInfo:
			'Door op de knop "Gebruiker aanmaken" te klikken, wordt een e-mail met instructies om een wachtwoord aan te maken naar het e-mailadres van de gebruiker gestuurd.',
	},
	fireAlarmShutdown: {
		idTitle:
			'Welkom bij de applicatie voor brandalarm uitschakelen & heet werk',
		idInfoText:
			'Om deze taken te kunnen aanvragen, moet er een goedgekeurde werkvoorbereiding zijn die een ID-nummer genereert dat hieronder wordt ingevoerd.',
		fillWorkPrep: 'Hier vult u uw werkvoorbereiding in',
		findId: 'Zo vindt u het ID-nummer van een goedgekeurde werkvoorbereiding',
		questions_NL:
			'Voor vragen neem contact op: \n Stadshart Amstelveen: Eelco.prinse@urw.com \n  Mall of the Netherlands: Christian.schultz@urw.com',

		idInput: 'Work preperation ID',
		idSubmit: 'Vul brandalarm uitschakelen & heet werk in',
		idChecking: 'ID-nummer controleren',
		idSubmitFail: 'Geef een geldig ID',
		idWrongFormat: 'Geef ID in het formaat XXXX-XX-XX',
		noAccess: 'U kunt deze pagina niet openen zonder een geldig ID',
		form: {
			title: 'Aanvraag voor brandalarm uitschakelen & heet werk',
			applies: 'Van toepassing op werkvoorbereiding met ID: ',
			fireAlarmOrHotWork: 'Brandalarm uitschakelen of heet werk',
			fireAlarm: 'Brandalarm uitschakelen',
			hotWork: 'Heet werk',
			workArea:
				'Gebied/bedrijf/pand dat door de uitschakeling wordt beïnvloed',
			shutdownReason: 'Reden voor uitschakeling:',
			dateShutdown: 'Datum van uitschakeling:',
			dateReconnect: 'Datum van heraansluiting:',
			nameApplicant: 'Naam contactpersoon ter plaatse',
			phonenumberApplicant: 'Telefoonnummer contactpersoon ter plaatse',
			emailApplicant: 'E-mail contactpersoon ter plaatse',
			companyApplicant: 'Bedrijf contactpersoon ter plaatse',
			contactPerson: 'Contactpersoon bij de vastgoedeigenaar',
			submitForm: 'Dien brandalarm uitschakelen & heet werk in',
			submittingForm: 'Formulier voorbereiden...',
			uploadingData: 'Gegevens uploaden',
			submittedForm: 'Brandalarm uitschakelen & heet werk ingediend',
			submittedFormError: 'Er is iets misgegaan, probeer het opnieuw',
			times: 'Tijden waarop het brandalarm moet worden uitgeschakeld',
			timesInfo:
				'Bij het uitschakelen van het brandalarm "ALLE DAGEN, RONDOM DE KLOK" is een bemande brandwacht vereist gedurende de gehele periode',
			weekdays: 'Weekdagen (maandag - vrijdag)',
			weekends: 'Weekenden (zaterdag + zondag + feestdagen)',
			aroundTheClock: 'Alle dagen, rondom de klok*',
			aroundTheClockWithoutInfo: 'Alle dagen, rondom de klok',
			creator: 'Contactpersoon',
			contactPersonOnSite:
				'Contactpersoon ter plaatse bij de vergunningaanvrager tijdens de uitschakeling',
			assentlyError:
				'Er is iets misgegaan met de eSign-service. Zorg ervoor dat alle e-mails correct zijn.',
			addContactPerson: 'Voeg contactpersoon ter plaatse toe',
			infoBox: {
				title: 'Vereisten bij het uitschakelen van het brandalarm:',
				bullet1:
					'Het werk mag niet beginnen voordat de facility manager heeft bevestigd dat het brandalarm is uitgeschakeld zoals aangevraagd door de vergunningaanvrager.',
				bullet2: '',
				bullet3:
					'Wanneer het brandalarm is uitgeschakeld, moet het personeel van de vergunningsaanvrager altijd binnen het afgesloten gebied zijn om snel een brand te detecteren, alarm te slaan en te proberen de brand te blussen. Dit geldt ook tijdens werkpauzes. Het alarm moet worden geactiveerd door op een alarmknop te drukken en de bewaker te contacteren. Als de werkplek tijdens de inspectie niet bemand is, wordt de vergunning ongeldig verklaard en wordt het brandalarm geactiveerd. Alternatief wordt het afgesloten gebied gescheiden van openbare delen in ten minste E30 of voorzien van gebouwbrandalarmen.',
				bullet4_NL:
					'Eventuele kosten die door de hulpdiensten worden gemaakt in geval van een brandalarm veroorzaakt door nalatigheid van de vergunningaanvrager, worden betaald door de vergunningsaanvrager. Er is ook een extra administratieve kost van EUR 1.250 voor de afhandeling van de inspanning door Unibail-Rodamco-Westfield.',
				bullet5:
					'Evacuatiealarmen in openbare ruimtes moeten in werking zijn.',
				bullet6:
					'*Bij het uitschakelen van het brandalarm voor "ALLE DAGEN, RONDOM DE KLOK" is een bemande brandwacht vereist gedurende de gehele periode.',
			},
			stepper: {
				scope: 'Omvang',
				sign: 'Ondertekenen',
			},
			submitted: 'Ingediend',
			notSubmitted: 'E-sign was geannuleerd',
			submittedInfoText_NL:
				'De aanvraag is nu door u ondertekend, voordat het betreffende werk kan beginnen, moet de aanvraag worden goedgekeurd door het management. Beslissingen worden via e-mail gecommuniceerd. Voor vragen over een aanvraag neem contact op: \n \n Stadshart Amstelveen: Eelco.prinse@urw.com \n  Mall of the Netherlands: Christian.schultz@urw.com',
			notSubmittedInfoText:
				'Het ondertekenen van het werk is geannuleerd, een herinnering wordt binnen een dag naar het opgegeven e-mailadres gestuurd',
			toStart: 'Om te beginnen',
			tryAgain: 'Terug naar ondertekenen',
		},
		edit: {
			updating: 'Brandalarm uitschakelen & heet werk bijwerken...',
			updated: 'Brandalarm uitschakelen & heet werk bijgewerkt!',
		},
	},
	serviceWork: {
		title: 'Servicewerk',
		introText:
			'Goedgekeurd servicewerk moet door het personeel ter plaatse worden gepresenteerd tijdens het werk.',
		submittingServiceWork: 'Servicewerk voorbereiden...',
		uploadingData: 'Gegevens uploaden',
		serviceWorkSubmitted: 'Het servicewerk is nu ingediend!',
		serviceWorkLogin: {
			title: 'Aanvraag voor servicewerk',
			info: 'De aanvraag voor servicewerk wordt gedaan via het beheerpaneel en vereist een account. Log in of stuur een e-mail naar het onderstaande adres om een account aan te vragen.',
			loginInfo: 'Log hier in op het beheerpaneel!',
			emails: 'Aanvraag voor account wordt gedaan naar: \n Westfield Mall of Scandinavia - work.wmos@urw.se \n Westfield Täby centrum - work.wtc@urw.se \n Nacka Forum - work.nacka@urw.se',
			subject: 'Voer onderwerp in: Accountaanvraag.',
		},
	},
	workPrep: {
		title: 'Werkvoorbereiding',
		introText:
			'Goedgekeurde werkvoorbereiding moet door het personeel ter plaatse worden gepresenteerd tijdens het werk.',
		workSteps:
			'Werkstappen (Korte beschrijving van wat moet worden uitgevoerd)',
		relevantMall: 'Relevante winkelcentrum',
		createdByCompany: 'Gemaakt door (bedrijfsnaam)',
		createdByPersonName: 'Gemaakt door (naam)',
		telephoneNumberToCreator: 'Telefoonnummer van de maker',
		emailToCreator: 'E-mailadres van de maker',
		status: 'Status',
		statusChangedBy: 'Status gewijzigd door',
		establishedDate: 'Gemaakt',
		event: 'Evenement',
		createdBy: 'Gemaakt door',
		workArea:
			'Werkgebied (Winkel, winkelcentrum gang, technische ruimte, etc.)',
		floor: 'Floor',
		numPeople: 'Aantal mensen',
		storeIdOrName: 'Winkel ID/naam',
		startDate: 'Startdatum',
		endDate: 'Einddaum',
		startingTime: 'Startijd',
		endingTime: 'Eindijd',
		date: 'Dtum',
		signStatus: 'Tekening status',
		signatureType: 'Tekeningstype',
		projectManager: 'Tenant coordinator/ project manager',
		management: 'Management',

		hotWork: 'Heet werk',
		hotWorkPermissionQuestion: 'Vereist het werk heetwerkcertificaten?',
		hotWorkPermissionComment:
			"De aanvraag voor brandalarmuitschakeling en heet werk wordt apart gedaan nadat de werkvoorbereiding is goedgekeurd. Via het hoofdmenu selecteert u 'Brandalarmuitschakeling en heet werk invullen' en vult u uw ID-nummer in, dat u ontvangt wanneer de werkvoorbereiding is goedgekeurd.",
		hotWorkPermitManager: 'Heet werk (Vergunningbeheerder)',

		fireAlarmWillBeShutOff: 'Brandalarm uitgeschakeld',
		fireAlarmWillBeShutOffQuestion:
			'Moet het brandalarm worden uitgeschakeld?',
		fireAlarmWillBeShutOffComment:
			"De aanvraag voor brandalarmuitschakeling en heet werk wordt apart gedaan nadat de werkvoorbereiding is goedgekeurd. Via het hoofdmenu selecteert u 'Brandalarmuitschakeling en heet werk invullen' en vult u uw ID-nummer in, dat u ontvangt wanneer de werkvoorbereiding is goedgekeurd.",
		duringWhatTimes: 'Tijdens welke tijden en data?',

		contactDriftHotWork:
			'Neem minstens 48 uur voor het hete werk contact op met het beheer van het betreffende centrum.',
		contactDriftFireAlarm:
			'eem minstens 48 uur voor de uitschakeling van het brandalarm contact op met het beheer van het betreffende centrum.',
		contactDriftEmail:
			'<1>Westfield Mall of Scandinavia: </1><2>mos@vimini.se</2><3 /><4>Täby Centrum: </4><5>tabycentrum@propertypartner.se</5><6 /><7>Nacka Forum: </7><8>nacka@alova.se</8><9> (Phone number: 08 - 4080 89 40, Werkuren: 07:00-17:00)</9>',
		contactDriftEmailShouldInclude: 'De e-mail moet bevatten:',
		contactDriftEmailShouldIncludeHotWork:
			'<1>1. Title in subject "Uniek ID-nummer + heet werk"</1><2 /><3>2. Goedgekeurde werkvoorbereiding in PDF-formaat</3><4 /><5>3. Voorgestelde datum en tijd voor het hete werk</5><6 /><7>4. Contactpersoon</7>',
		contactDriftEmailShouldIncludeFireAlarmWithLink:
			'<1>1. Title in subject "Uniek ID-nummer + brandalarmuitschakeling"</1><2 /><3>2. Ingevuld formulier voor de uitschakeling in PDF-formaat. </3><4>Hier kunt u het formulier downloaden</4><5 /><6>3. Goedgekeurde werkvoorbereiding in PDF-formaat</6>',
		contactDriftEmailShouldIncludeFireAlarm:
			'<1>1. Title in subject "Uniek ID-nummer + brandalarmuitschakeling"</1><2 /><3>2. Ingevuld formulier voor de uitschakeling in PDF-formaat. </3><4 /><5>3. Goedgekeurde werkvoorbereiding in PDF-formaat</5>',
		contactDriftContactPerson:
			'De aanvraag moet altijd worden gedaan met een contactpersoon van URW in cc',

		workWithWaterWillBeDoneQuestion:
			'Will work on watersystems be carried out?',
		workWithWaterCertificateComment:
			'Attach certificate from "Säker Vatten"',
		chooseWaterCertificate: 'Choose certificate from "Säker Vatten"',
		safeWaterComment:
			'Here you can read more about the<1> safe water certificate</1>',

		ventilationWillBeShutOff: 'Ventilatie uitgeschakeld',
		ventilationWillBeShutOffQuestion:
			'Moet de ventilatie worden uitgeschakeld?',
		ventilationWillBeShutOffComment1: 'Houd er rekening mee dat als u ',
		ventilationWillBeShutOffComment2: 'JA ',
		ventilationWillBeShutOffComment3:
			'kiest, het exploitatiebedrijf van het centrum moet onderzoeken of dit mogelijk is, wat kosten met zich mee kan brengen.',

		electricalWorkWillBeDoneQuestion:
			'Wordt er elektrisch werk uitgevoerd?',
		electricalWorkWillBeDoneComment:
			'Hier kunt u het<1> certificaat voor de elektrische installateur van de Zweedse Elektrische Veiligheidsautoriteit</1> krijgen"</1>',
		electricalWorkCertificateComment_NL:
			'Voeg een aanwijzing voor elektrotechnische werkzaamheden toe',
		chooseElectricalWorkCertificate:
			'Kies certificaat van de Elektrische Veiligheidsautoriteit',
		sprinklerSystemWillBeShutOff: 'Sprinklersysteem uitgeschakeld',
		sprinklerSystemWillBeShutOffQuestion:
			'Moeten de sprinklersystemen worden uitgeschakeld?',
		sprinklerSystemWillBeShutOffComment1: 'Houd er rekening mee dat als u ',
		sprinklerSystemWillBeShutOffComment2: 'JA ',
		sprinklerSystemWillBeShutOffComment3:
			'kiest, het sprinklerbedrijf van het centrum moet onderzoeken of dit mogelijk is, wat kosten met zich mee kan brengen.',

		constructionWorkWillTakePlaceQuestion:
			'Vinden er bouwwerkzaamheden plaats?',
		constructionWorkWillTakePlaceComment:
			'Wat valt onder<1> bouwwerkzaamheden</1>?<2 /><3>Hier vindt u meer informatie over een werkmilieuplan</3>',
		constructionWorkWillTakePlaceComment_NL:
			'<constructionWorkLink>Via deze link vind je meer informatie over een V&G plan</constructionWorkLink>',
		attachConstructionEnvironmentWorkPlanComment_NL:
			'Voeg een V&G-plan voor de bouwwerkzaamheden toe',

		willTheWorkTakeLongerThan30DaysQuestion:
			'Will the work take more than 30 days and consist of more than 20 people at any one time employed at the same time?',
		willTheWorkTakeLongerThan30DaysQuestionShort:
			'Will the work take more than 30 days and consist of more than 20 people',
		willTheWorkTakeLongerThan30DaysComment:
			'Here you will find more information about<1> advance notification of construction sites</1>',
		willTheWorkTakeLongerThan30DaysCertificateComment:
			'Attach prior notification of the construction site to the Swedish Work Environment Authority',

		onlyBiggerContracts:
			'Only for larger contracts, check with your client if required',
		registrationOfProject: 'Registration of projects takes place',

		attachScheduleForTheProjectComment: 'Attach schedule for the project',
		scheduleForTheProject: 'Schedule for the project',

		baseP: 'BAS-P',
		basePExistsQuestion: 'Is there a BAS-P?',
		basePExistsComment:
			"The work preparation applies together with URW's guidelines for working environment with associated rules of procedure and protection, these must be available to the executor.<1 /><2>Here you will find more information about BAS-U/P</2>",
		nameToBaseP: 'Name to BAS-P',
		telephoneToBaseP: 'Telephone to BAS-P',
		emailToBaseP: 'Email to BAS-P',

		projectManagerExistsQuestion:
			'Heb je een tenant coordinator/project manager bij URW?',
		nameToProjectManager: 'Naam',
		telephoneToProjectManager: 'Telefoonnummer',
		emailToProjectManager: 'Email',

		insurance: 'Verzekering',
		insuranceExistsQuestion: 'Verzekering is beschikbaar en betaald.',
		insuranceCompanyNameQuestion_NL: 'Voer CAR-verzekeringsmaatschappij in',
		insuranceContractNumberQuestion: 'Contract nummer invullen',
		liabilityInsuranceNameQuestion:
			'Voer een aansprakelijkheidsverzekeringsmaatschappij in',
		liabilityInsuranceContractNumberQuestion: 'Contract nummer invullen',

		insuranceCompanyNameComment:
			'Als u de naam niet weet, neem dan zo snel mogelijk na de indiening van de werkvoorbereiding contact met ons op zodra u het heeft ontdekt.',

		certainRisks: {
			question: "Wat zijn de bepaalde risico's die zich voordoen?",
			comment_NL:
				'"Bepaalde risico\'s" volgens de Arbowet en andere risico\'s op de werkplek.',
			fallRisk: 'Valrisico (bij werken op een hoogte van meer dan 2 m)',
			hazardousChemOrBioSubstances:
				'Gevaarlijke chemische of biologische stoffen',
			heavyLifts: 'Zwaar tillen van ... bouwelementen',
			passingVehicleTraffic: 'Voorbijgaand verkeer',
			dust: 'Stof',
			vibrationsOrnoise: 'Trillingen / lawaai',
			fireHazard: 'Brandgevaar',
			other: 'Andere',
		},

		areaWhereWorkWillBePerformed: {
			question: 'Gebied, waar zal het werk worden uitgevoerd?',
			restaurant: 'Restaurant',
			store: 'Winkel',
			shoppingMallAisle: 'Winkelcentrum gangpad',
			garage: 'Garage',
			technicalSpace: 'Technische ruimte',
			serviceCorridor: 'Expeditiegang',
			workOutsideFacade: 'Werk buiten gevel',
			squareOrEntrance: 'Plein / Ingang',
			alreadyFilledInWithVGPlan: 'Reeds ingevuld met V&G-plan',
		},

		measureForWorkEnvironmentRisks:
			"Maatregel voor arbeidsomgevingsrisico's",
		measureForWorkEnvironmentRisksComment:
			"Geef de werkstappen aan waar arbeidsomgevingsrisico's bestaan zoals hierboven en beschrijf maatregelen om het risico op letsel te verminderen.",

		validation: {
			atLeastOnePerson: 'Moet minstens één persoon zijn',
		},

		infoBox1: {
			title: 'Houd het volgende in gedachten bij het voorbereiden van de werkstappen',
			bullet1: 'Vluchtroutes mogen niet worden geblokkeerd.',
			bullet2:
				'Apparatuur, liften en materialen mogen na het einde van de dag niet in het werkgebied worden achtergelaten en er moet dagelijks worden schoongemaakt.',
			bullet3:
				'Al het hete werk vereist een vergunning, als sprinklers en brandalarmen worden beïnvloed, moet dit worden gemeld.',
			bullet4_NL:
				'Al het personeel dat in de gebouwen werkt, moet bij het inchecken een geldig identiteitsbewijs (EU), paspoort of Nederlands rijbewijs tonen.',
			bullet5:
				'Brandafdichtingen tussen brandcellen moeten aan het einde van de werkdag worden hersteld.',
			bullet6:
				'Het werkgebied moet worden afgedekt om schade aan eigendommen als gevolg van bijvoorbeeld stofverspreiding te minimaliseren, waar vereist met betrekking tot omliggende activiteiten.',
			bullet7:
				'Zogenaamde Stofbeheersing <1>SHOULD</1> op het werkoppervlak aanwezig zijn tijdens stof- en rookgenererende werkzaamheden.',
			bullet8:
				'Maatregelen zoals wegafsluitingen moeten worden genomen om derden te beschermen.',
			bullet9: 'Vereiste beschermingsmiddelen moeten worden gebruikt.',
			bullet10_NL:
				'In geval van een scherp brandalarm, de kosten van het oproepen van de hulpdiensten + een administratieve kost van EUR 1.250 voor de afhandeling door Unibail-Rodamco-Westfield. De kosten worden rechtstreeks gefactureerd aan de huurder die de evacuatie heeft veroorzaakt.',
			bullet11:
				"Bij eventuele afwijkingen op de werkplek die door de administratie worden geconstateerd tijdens 'Vliegende Controle', heeft de administratie het recht om lopende werkzaamheden te beëindigen, afhankelijk van het type fout.",
		},

		infoBox2: {
			title: 'Om rekening mee te houden bij het beschrijven van werkstappen',
			bullet1: 'Ventilatietechnische aanpassingen.',
			bullet2:
				'Trillingen / Lawaai; Afhandeling tegen eventuele interne alarmen van huurders, Werk vindt plaats na sluitingstijd van winkels.',
			bullet3:
				'Documentatie; Zelfcontroles en eventueel afwijkingsrapporten.',
			bullet4_NL:
				'Al het personeel moet een geldig identiteitsbewijs (EU), paspoort of Nederlands rijbewijs dragen.',
			bullet5:
				'Afvalverwijdering. Waar wordt de container geplaatst, wiens container wordt gebruikt?',
		},

		describeWorkSteps: 'Beschrijf werkstappen',
		describeWorkStepsComment:
			'Denk aan methode, materiaal, apparatuur, personeel, etc.',

		additionalResources: {
			title: 'Middelen naast materialen',
			comment: 'Bijvoorbeeld machines, hulpmiddelen en externe middelen.',
			scissorLift: 'Schaarlift',
			skylift: 'Skylift',
			container: 'Container',
			typeApprovedLadder: 'Type-goedgekeurde ladder',
			otherAdditionalResource: 'Andere',
			attachLiftCardsForPersonalWorkingWithLifts:
				'Voeg liftkaarten toe voor werknemers die met de liften werken',
			chooseLiftCards: 'Kies liftkaarten',
		},

		gdpr: 'Door het invullen en indienen van de werkvoorbereiding stemt u ermee in dat uw persoonlijke gegevens in de vorm van namen en contactgegevens worden verwerkt door de eigenaar van het pand en het moederbedrijf om het werk dat in de werkvoorbereiding is opgenomen te beheren en te beheren. Uw persoonlijke gegevens worden tot een maand na het einde van het werk opgeslagen. U heeft het recht om uw rechten uit te oefenen (bijv. verzoek om toegang tot of correctie van persoonlijke gegevens) volgens de Algemene Verordening Gegevensbescherming door contact op te nemen met de eigenaar van het pand via dp.nordics@urw.com. Het is uw verantwoordelijkheid om ervoor te zorgen dat de bovenstaande informatie de persoon bereikt wiens persoonlijke gegevens in de documentatie voorkomen.',
		gdpr_NL:
			'Door het invullen en indienen van de werkvoorbereiding stemt u ermee in dat uw persoonlijke gegevens in de vorm van namen en contactgegevens worden verwerkt door de eigenaar van het pand en het moederbedrijf om het werk dat in de werkvoorbereiding is opgenomen te beheren en te beheren. Uw persoonlijke gegevens worden tot een maand na het einde van het werk opgeslagen. U heeft het recht om uw rechten uit te oefenen (bijv. verzoek om toegang tot of correctie van persoonlijke gegevens) volgens de Algemene Verordening Gegevensbescherming door contact op te nemen met de eigenaar van het pand via data.protection@urw.com. Het is uw verantwoordelijkheid om ervoor te zorgen dat de bovenstaande informatie de persoon bereikt wiens persoonlijke gegevens in de documentatie voorkomen.',

		warningText_NL: `In geval van een geactiveerd brandalarm, de kosten van het oproepen van de hulpdiensten + een administratieve vergoeding van EUR 1.250 voor de afhandeling door Unibail-Rodamco-Westfield. De kosten worden rechtstreeks gefactureerd aan de huurder die de oproep heeft veroorzaakt.<br />
			Goedgekeurde werkvoorbereiding moet op verzoek van bevoegd personeel op de werkplek kunnen worden getoond.<br />
			In geval van dringende gebeurtenissen of vragen met betrekking tot het uitschakelen van het brandalarm tijdens het werk of als het brandalarm wordt geactiveerd in het werkgebied, neem onmiddellijk contact op met de teamleider voor bewakers:<br /><br />
			Stadshart Amstelveen - 06 53935260<br />
			Mall of the Netherlands – 070 763 05 34<br /><br />
			In het geval van brandbeveiligingsmaatregelen moeten na het einde van het werk sms-berichten worden gestuurd naar de groepsleiders van de beveiliging.`,

		submittingWorkPrep: 'Werkvoorbereiding indienen...',
		workPrepSubmitted: 'De werkvoorbereiding is nu ingediend!',
		pdfTooltip: 'Alleen PDF-bestanden worden geaccepteerd',
		onlyPDF: 'Zorg ervoor dat u alleen PDF-bestanden bijvoegt ',
		assentlyError:
			"Er is iets misgegaan met de eSign-service. Zorg ervoor dat alle e-mails correct zijn en dat alle PDF's geldig zijn",
		uploadButton: 'Upload PDF',
		pdfSizeLimit: 'Maximale bestandsgrootte is 15 Mb',
		continue: 'Doorgaan',
		back: 'Terug',
		sign: 'Ondertekenen',
		save: 'Wijzigingen opslaan',
		stepper: {
			creator: 'Maker',
			work: 'Werk',
			requirements: 'Vereisten',
			sign: 'Ondertekenen',
		},
		submitted: 'Ingediend',
		notSubmitted: 'E-handtekening geannuleerd',
		submittedInfoText_NL:
			'De werkvoorbereiding is nu door u ondertekend. Voordat het betreffende werk kan beginnen, moet de werkvoorbereiding worden goedgekeurd door het management. Beslissingen worden per e-mail gecommuniceerd. Voor vragen over een werkvoorbereiding kunt u contact opnemen met: \n \n Stadshart Amstelveen: Eelco.prinse@urw.com \n  Mall of the Netherlands: Christian.schultz@urw.com',
		notSubmittedInfoText:
			'Het ondertekenen van de werkvoorbereiding is geannuleerd, een herinnering wordt binnen een dag naar het opgegeven e-mailadres gestuurd',
		toStart: 'Naar het begin',
		urlError:
			'Er is iets misgegaan met de eSign-service. Neem contact op met het beheer van het betreffende centrum',
		iHaveRead: 'Ik heb de bovenstaande tekst gelezen',
		tryAgain: 'Terug naar ondertekenen',
		isLoggedInFileInfo:
			'Hieronder vindt u uw bestaande bestanden. Selecteer een bestand uit de lijst of upload een nieuwe PDF.',
		noDocument: 'Geen document',
		fireAlarmAndSprinklerInfo_NL: {
			title: 'Let op! Uitgeschakelde brandalarm- en sprinklersystemen vereisen dat u de sprinklerbeheerder van het centrum op de hoogte stelt.',
			amstelveen: 'robert.belt@firesolutions.cegelec.com',
			mallOfTheNetherlands: 'servicewoerden@degrootgroep.nl',
		},
		clientTitle: 'Klant',
		clientName: 'Klantnaam',
		clientTelephone: 'Klant telefoonnummer',
		clientEmail: 'Klant e-mail',
		uploadingFiles: 'Bestanden uploaden',
		workEnviromentResponsibility: {
			title: 'Ik bevestig dat ik, als uitgever, me ervan bewust ben dat',
			bullet1:
				'Ik, als werkgever, verantwoordelijk ben voor de werkomgeving van mijn werknemers,',
			bullet2_NL:
				'de klant (ofwel de winkel of Unibail-Rodamco-Westfield) verantwoordelijk is voor de werkomgeving als ontwikkelaar, en',
			bullet3_NL:
				'Unibail-Rodamco-Westfield is als eigenaar van het pand uitsluitend verantwoordelijk voor de coördinatie van zaken rondom de werkomgeving in het centrum. Dit ontslaat noch de klant, noch de werkgevers van hun verantwoordelijkheden ten aanzien van de werkomgeving.',
		},
	},
	workPrepPDF: {
		hotWorkPermitManager: 'Heet werk (Vergunningbeheerder)',
		fireAlarmWillBeShutOffTimePeriod:
			'Brandalarm uitgeschakeld (Tijdsperiode)',
		switchedOffVentilation: 'Ventilatie uitgeschakeld',
		switchedOffVentilationTimePeriod:
			'Ventilatie uitgeschakeld (Tijdsperiode)',
		switchedOffSprinklerSystem: 'Sprinklersysteem uitgeschakeld',
		switchedOffSprinklerSystemTimePeriod:
			'Sprinklersysteem uitgeschakeld (Tijdsperiode)',
		clientName: 'Klantnaam',
		clientTelephone: 'Klant telefoonnummer',
		clientEmail: 'Klant telefoonnummer',
		projectManagerExist: 'Tenant coordinator/project manager bestaat',
		projectManagerName: 'Tenant coordinator/project manager naam',
		projectManagerPhoneNumber:
			'Telefoonnummer Tenant coordinator/project manager',
		projectManagerEmailAddress:
			'E-mailadres Tenant coordinator/project manager',
		urwBasUExist: 'BAS-U exists',
		urwBasUName: 'BAS-U name',
		urwBasUPhoneNumber: 'BAS-U phone number',
		urwBasUEmailAddress: 'BAS-U email address',
		basPExist: 'BAS-P exists',
		basPName: 'BAS-P name',
		basPPhoneNumber: 'BAS-P phone number',
		basPEmailAddress: 'BAS-P email address',
		insuranceExist: 'Verzekering bestaat',
		insuranceCompany: 'Verzekeringsmaatschappij',
		liabilityInsuranceCompany: 'Aansprakelijkheidsverzekeringsmaatschappij',
		insuranceContactNumber: 'Contractnummer',
		certainRisks: "Bepaalde risico's",
		remediesForWorkEnvironmentRisks:
			"Maatregelen voor arbeidsomgevingsrisico's",
		additionalResources: 'Aanvullende middelen',
		area: 'Gebied',
		descriptionOfWorkSteps: 'Beschrijving van werkstappen',
		errorOccured:
			'Een of meer van de PDF-bestanden konden niet worden samengevoegd',
	},
	adminPanel: {
		title: 'Beheerpaneel',
		workpreps: 'Werkvoorbereidingen',
		helperText:
			'Klik op een rij om details te zien over een specifieke werkvoorbereiding.',
		all: 'Alle',
		archived: 'Gearchiveerd',
		notArchived: 'Niet Gearchiveerd',
		newWorkPrep: 'Nieuwe werkvoorbereiding',
		search: 'Zoek werkvoorbereiding',
		searchId: 'Zoek ID',
		searchCompany: 'Zoek bedrijf',
		searchName: 'Zoek naam',
		allMall: 'Alle',
		relevantMall: 'Relevant winkelcentrum',
		activeWorks: 'Actieve werkzaamheden',
	},
	fireAlarmPanel: {
		fireAlarms: 'Brandalarm uitschakelingen & Heet werk',
		helperText:
			'Klik op een rij om details te zien over een specifiek werk.',
		type: 'Type',
		newFireAlarm: 'Aanvraag voor brandalarm uitschakeling & Heet werk',
		search: 'Zoek werk',
	},
	serviceWorkPanel: {
		serviceWorks: 'Service werkzaamheden',
		helperText: 'Klik op een rij om details te zien over een service werk.',
		newServiceWork: 'Nieuw service werk',
		search: 'Zoek service werk',
	},
	fireAlarmDetails: {
		title: 'Brandalarm uitschakeling & Heet werk',
		created: 'Gemaakt',
		updatingStatus: 'Status bijwerken in database...',
		statusUpdated: 'Status bijgewerkt!',
		fireAlarmOrHotWork: 'Brandalarm uitschakeling of Heet werk',
		fireAlarm: 'Brandalarm uitschakeling',
		hotWork: 'Heet werk',
		workArea: 'Gebied/bedrijf/pand getroffen door de uitschakeling',
		shutdownReason: 'Reden voor uitschakeling:',
		approve: 'Werk goedkeuren',
		reject: 'Werk afkeuren',
		remove: 'Werk verwijderen',
		openPDF: 'Open als PDF',
		relevantMall: 'Relevant winkelcentrum',
		startDate: 'Startdatum',
		startingTime: 'Starttijd',
		endDate: 'Einddatum',
		endingTime: 'Eindtijd',
		createdByCompany: 'Gemaakt door (bedrijfsnaam)',
		createdByName: 'Gemaakt door (naam)',
		phoneNumber: 'Telefoonnummer van de maker',
		email: 'E-mailadres van de maker',
		workPrepStore: 'Werkvoorbereiding: Winkel ID/Naam',
		workPrepFloor: 'Werkvoorbereiding: Verdieping',
		workPrepWorkArea: 'Werkvoorbereiding: Werkgebied',
		drift: 'Drift',
		scrollMenu: {
			workPrep: 'Werkvoorbereiding',
			overview: 'Overzicht',
			creator: 'Gemaakt door',
			signStatus: 'Handtekeningstatus',
		},
		toWorkprep: 'Naar werkvoorbereiding',
		edit: 'Wijzigingen aanbrengen in werk',
		completionRequest: 'Verzoek om voltooiing van werk',
	},
	serviceWorkDetails: {
		title: 'Service Works',
		created: 'Gemaakt',
		updatingStatus: 'Status bijwerken in database...',
		statusUpdated: 'Status bijgewerkt!',
		approve: 'Service Werk goedkeuren',
		reject: 'Service Werk afkeuren',
		remove: 'Service Werk verwijderen',
		openPDF: 'Open als PDF',
		relevantMall: 'Relevant winkelcentrum',
		startDate: 'Startdatum',
		startingTime: 'Starttijd',
		endDate: 'Einddatum',
		endingTime: 'Eindtijd',
		createdByCompany: 'Gemaakt door (bedrijfsnaam)',
		createdByName: 'Gemaakt door (naam)',
		phoneNumber: 'Telefoonnummer van de maker',
		email: 'E-mailadres van de maker',
		store: 'Winkel ID/Naam',
		floor: 'Verdieping',
		workArea: 'Werkgebied',
		numPeople: 'Aantal mensen',
		workSteps: 'Werkstappen',
		scrollMenu: {
			overview: 'Overzicht',
			creator: 'Gemaakt door',
			signStatus: 'Handtekeningstatus',
		},
		makeChanges: 'Wijzigingen aanbrengen in service werk',
		completeWorkPrepRequest: 'Verzoek om voltooiing',
	},
	fireAlarmPDF: {
		title: 'Brandalarm uitschakeling & Heet werk',
	},
	workPrepDetails: {
		created: 'Gemaakt',
		updating: 'Status bijwerken in database...',
		updated: 'Status bijgewerkt!',
		newSignatureRequest: 'Handmatig nieuwe handtekeningen aanvragen',
		newSignatureRequested: 'Verzoek verzonden!',
		newSignatureRequestTooltip:
			'Gebruik alleen als werkvoorbereiding handmatig is bewerkt in het beheerpaneel',
		approveTooltip:
			'U kunt alleen een werkvoorbereiding goedkeuren die door iedereen is ondertekend',
		completeWorkPrepRequest:
			'Verzoek om voltooiing van de werkvoorbereiding',
		completeWorkPrepRequestModal: {
			title: 'Voltooiingsverzoek',
			completionRequestMessageTitle: 'Bericht',
			submitRequest: 'Verzend voltooiingsverzoek',
			closeModal: 'Sluiten',
			sendingRequest: 'Verzoek verzenden...',
			requestSent: 'Voltooiingsverzoek verzonden!',
		},
		commentSection: 'Opmerkingen',
		commentWorkPrep: 'Opmerking toevoegen',
		commentModal: {
			title: 'Opmerking werkvoorbereiding',
			messageHeaderTitle: 'Kop',
			messageTitle: 'Opmerking',
			submitRequest: 'Opmerking toevoegen',
			addingComment: 'Opmerking toevoegen...',
			closeModal: 'Sluiten',
			requestSent: 'Opmerking toegevoegd!',
			commentCreator: 'Naam',
		},
		approve: 'Werkvoorbereiding ondertekenen als management',
		approveModal: {
			title: 'Werk goedkeuren',
			messageInfo: 'Bericht wordt opgenomen in e-mail',
			messageTitle: 'Bericht',
			submitRequest: 'Werk goedkeuren',
			closeModal: 'Sluiten',
		},
		rejectModal: {
			title: 'Werk afkeuren',
			messageInfo: 'Bericht wordt opgenomen in e-mail',
			messageTitle: 'Bericht',
			submitRequest: 'Afkeuren',
			closeModal: 'Sluiten',
		},
		deleteModal: {
			title: 'Werk verwijderen',
			helperText: 'Deze actie kan NIET ongedaan worden gemaakt',
			submitRequest: 'Werk verwijderen',
			closeModal: 'Sluiten',
		},
		sendReminder: 'Herinnering sturen voor ondertekening',
		reminderSent: 'Herinnering verzonden',
		noReminder: 'Geen actieve ondertekening gevonden',
		reminderSentError:
			'De eSign-service gaf een fout, geen herinnering verzonden',
		issuedBy: 'Uitgegeven door',
		date: 'Datum',
		message: 'Bericht',
		completionRequested: 'Voltooiing aangevraagd',
		lastEdited: 'Laatst bewerkt',
		lastReminded: 'Laatste herinnering',
		updates: 'Updates',
		Touch: 'Touch',
		Sms: 'Sms',
		ElectronicId: 'Mobil BankID',
		AdminPanel: 'Beheerpaneel',
		scrollMenu: {
			overview: 'Overzicht',
			creator: 'Gemaakt door',
			requirements: 'Vereisten',
			safety: 'Veiligheid',
			signStatus: 'Handtekeningstatus',
			comments: 'Opmerkingen',
		},
		attachments: 'Andere bijlagen',
		newFireAlarm: 'Brandalarm uitschakeling & Heet werk invullen',
		signProjectManager: 'Werkvoorbereiding ondertekenen als projectmanager',
		signProjectManagerToolTip:
			'Maker van werkvoorbereiding moet ondertekenen voordat projectmanager kan ondertekenen',
		event: 'Evenement',
		relevantMall: 'Relevante winkelcentrum',
		numberOfPeople: 'Aantal personen',
		workArea: 'Werkgebied',
		floor: 'Verdieping',
		storeIdOrName: 'Winkel-ID/Naam',
		startDate: 'Startdatum',
		endDate: 'Einddatum',
		startingTime: 'Starttijd',
		endingTime: 'Eindtijd',
		scheduleForTheProject: 'Schema voor het project',
		createdByCompany: 'Aangemaakt door (bedrijfsnaam)',
		createdByPerson: 'Aangemaakt door (naam)',
		telephoneNumber: 'Telefoonnummer van de maker',
		email: 'E-mailadres van de maker',
		insuranceExists: 'Verzekering aanwezig',
		insuranceCompanyName: 'Verzekeringsmaatschappij',
		certainRisks: 'Bepaalde risico’s',
		remediesForWorkEnvironmentRisks:
			'Maatregelen tegen risico’s in de werkomgeving',
		area: 'Gebied',
		descriptionOfWorkSteps: 'Beschrijving van werkstappen',
		additionalResources: 'Aanvullende middelen',
		projectManagerExists: 'Huurdercoördinator/projectmanager aanwezig',
		projectManagerName: 'Huurdercoördinator/projectmanager (naam)',
		projectManagerEmail: 'Huurdercoördinator/projectmanager (e-mailadres)',
		projectManagerPhone:
			'Huurdercoördinator/projectmanager (telefoonnummer)',
		openAsPDF: 'Openen als PDF',
		makeChangesInWorkPrep: 'Wijzigingen aanbrengen in werkvoorbereiding',
		rejectWorkPrep: 'Werkvoorbereiding afwijzen',
		deleteWorkPrep: 'Werkvoorbereiding verwijderen',
		notificationStatus: {
			pending:
				'De e-mailstatus wordt gecontroleerd. Kom over een minuut nog eens terug...',
			delivered: 'E-mail voor ondertekening is afgeleverd.',
			undeliverable:
				'E-mail voor handtekening kon niet worden afgeleverd. Begin met het sturen van een herinnering en controleer vervolgens of het e-mailadres correct is.',
		},
	},
	editWorkPrep: {
		completeRequest: {
			sent: 'Voltooiingsverzoek vervuld',
		},
		workPrepUpdated: 'Werkvoorbereiding bijgewerkt!',
		notAllRequiredFiles:
			'Zorg ervoor dat u alle vereiste bestanden heeft geüpload, in PDF-formaat',
		uploadedFiles: 'Geüploade bestanden:',
		requestNewSignaturesTitle: 'Nieuwe handtekeningen',
		requestNewSignatures:
			'Vraag nieuwe handtekeningen aan in verband met wijzigingen',
		warning: 'Wees voorzichtig, wijzigingen zijn niet omkeerbaar!',
	},
	login: {
		userDoesNotExist: 'De gebruiker die u heeft ingevoerd, bestaat niet.',
		wrongPassword: 'De gebruiker bestaat, maar het wachtwoord is onjuist.',
		wrongCredentials: 'Onjuiste e-mail of wachtwoord.',
		somethingWentWrong: 'Er is iets misgegaan, probeer het opnieuw.',
		login: 'Inloggen',
		email: 'E-mail',
		password: 'Wachtwoord',
		forgotPassword: 'Wachtwoord vergeten?',
		forgotPasswordPage: {
			send: 'Verzenden',
			sendingEmail: 'E-mail verzenden...',
			emailSent:
				'Er is een e-mail over het opnieuw instellen van het wachtwoord verzonden!',
		},
	},
	common: {
		lang: 'nl-NL',
		back: 'Terug',
		cancel: 'Annuleren',
		notLoggedInMsg: 'U moet eerst <1>inloggen</1> first!',
		erorOccured: 'Er is een fout opgetreden!',
		required: 'Vereist',
		atLeastOne: 'U moet ten minste één alternatief kiezen',
		yes: 'Ja',
		no: 'Nee',
		invalidEmailAddress: 'Ongeldig e-mailadres',
		invalidDate: 'De datum is onjuist geformatteerd',
		invalidTime: 'De tijd is onjuist geformatteerd',
		mustBeInteger: 'Moet een geheel getal zijn',
		passwordDoesNotMatch: 'Wachtwoorden komen niet overeen',
		seeErrorsAbove: 'Controleer de bovenstaande foutmeldingen!',
		here: 'hier',
		fileRequired: 'Bestandsbijlage is vereist',
		electricalWorkCertificate:
			'Certificaat van de Elektrische Veiligheidsautoriteit',
		workWithWaterCertificate: 'Certificate from "Säker Vatten"',
		scheduleForTheProject: 'Schema voor het project',
		constructionEnvironmentWorkPlan: 'Work environment plan',
		willTheWorkTakeLongerThan30DaysCertificate:
			'Voorafgaande kennisgeving van de bouwplaats (Zweedse Arbeidsomstandighedenautoriteit)',
		baseUCertificate: 'BAS-U certificate',
		basePCertificate: 'BAS-P certificate',
		attachedFiles: 'Bijgevoegde bestanden',
		liftCardsForPersonalWorkingWithLifts:
			'Liftkaarten voor de personen die met liften werken',
		waitPlease: 'Even geduld aub...',
		beforeToday: 'Selecteer een toekomstige datum',
		beforeStart: 'Selecteer een datum na de startdatum',
		badRedirectUUID: 'De link is niet langer geldig!',
		toStart: 'Naar het begin',
		projectManager: 'Projectmanager',
		confirmPrivacyPolicy:
			'Ik ga akkoord met de <privacy>privacybeleid</privacy> en heb deze gelezen',
	},
	adminMenu: {
		workPrep: 'Werkvoorbereidingen',
		awaitingApproval: 'In afwachting van goedkeuring',
		all: 'Alle',
		fireShutdown: 'Brandalarm uitschakelingen & Heet werk',
		serviceJobs: 'Service Works',
		workHistory: 'Werkgeschiedenis',
		makeAccount: 'Gebruiker aanmaken',
		logOut: 'Uitloggen',
		lang: 'Svenska',
		account: 'Gebruiker',
		accounts: 'Gebruikers',
		handleAccount: 'Gebruiker beheren',
		handleAccounts: 'Gebruikers beheren',
		documentCollection: 'Documentenverzameling',
		editAccount: 'Gebruikersgegevens',
		active: 'Actief',
		finished: 'Voltooid',
	},
	workPrepStatusDisplay: {
		status: {
			approved: 'Goedgekeurd',
			awaitingSigning: 'In afwachting van ondertekening',
			denied: 'Afgewezen',
			awaitingReview: 'In afwachting van beoordeling',
		},
	},
	fileUrlSingle: {
		hide: 'Certificaat verbergen',
		show: 'Certificaat tonen',
	},
	submitUserDocumentModal: {
		uploadDocument: 'Document uploaden',
		descriptionText:
			'Voeg hier tekst toe die beschrijft wat het betekent om een document te uploaden',
		documentName: 'Documentnaam',
		documentPerson: 'Geassocieerde persoon',
		documentType: 'Documenttype',
		selectDocument: 'Selecteer bestand',
		electricalWorkCertificate: 'Elektrisch certificaat',
		workWithWaterCertificate: 'Veilig water certificaat',
		baseUCertrificate: 'Bas-U certificate',
		basePCertificate: 'Bas-P certificate',
		liftCardsForPersonalWorkingWithLifts: 'Liftcertificaat',
		openLegalText: 'voorwaarden',
		agreeLegal: 'Ik heb gelezen en ga akkoord met deze',
		legalTitle: 'Documentverwerking',
		legalText:
			'Op deze pagina krijgt u de mogelijkheid om certificaten en andere soortgelijke documentatie te uploaden en op te slaan om de bevoegdheid aan te tonen om werkzaamheden uit te voeren in de faciliteiten van Unibail-Rodamco-Westfield. Dergelijke documentatie wordt 12 maanden in het systeem opgeslagen en wordt daarna automatisch verwijderd, tenzij u ervoor kiest om de opslagperiode te verlengen. U kunt geüploade documenten ook op elk moment verwijderen. Het doel van de mogelijkheid om de documenten op te slaan is om terugkerende werkzaamheden en het maken van nieuwe werkvoorbereidingen in dit portaal te vergemakkelijken. De documenten op deze pagina zijn zichtbaar voor degenen die zijn ingelogd op het gebruikersaccount. Het is uw verantwoordelijkheid om ervoor te zorgen dat de bovenstaande informatie de natuurlijke persoon bereikt wiens persoonlijke gegevens in de documentatie voorkomen.',
		cancel: 'Annuleren',
	},
	userDocuments: {
		documentCollection: 'Documentenverzameling',
		documentCollectionDescription:
			'Op deze pagina kunt u uw geüploade documenten bekijken, documenten vernieuwen, documenten verwijderen en nieuwe documenten uploaden.',
		uploadDocument: 'Document uploaden',
		document: 'DOCUMENT',
		name: 'NAAM',
		type: 'TYPE',
		expiryDate: 'VERVALDATUM',
		electricalWorkCertificate: 'ELEKTRISCH CERTIFICAAT',
		workWithWaterCertificate: 'SAFE WATER CERTIFICATE',
		basePCertificate: 'BAS-P CERTIFICATE',
		baseUCertificate: 'BAS-U CERTIFICATE',
		liftCardsForPersonalWorkingWithLifts: 'LIFT CERTIFICATE',
		deleteUserDocumentModal: {
			confirmDeletion:
				'Weet u zeker dat u het document wilt verwijderen?',
			deletionExplainer:
				'Beschrijf wat het betekent om een document te verwijderen',
			delete: 'Verwijderen',
			abort: 'Annuleren',
		},
		renewDocument: 'Document vernieuwen',
		renewingDocument: 'Document vernieuwen...',
		documentRenewed: 'Document vernieuwd!',
		uploadingDocument: 'Document uploaden...',
		documentUploaded: 'Document geüpload!',
		deletingDocument: 'Document verwijderen...',
		documentDeleted: 'Document verwijderd!',
	},
	userSettings: {
		handleAccount: 'Gebruikersgegevens',
		resetPassword: 'Wachtwoord opnieuw instellen',
		name: 'Naam',
		email: 'E-mail',
		role: 'Rol',
		password: 'Wachtwoord',
		save: 'Opslaan',
		cancel: 'Annuleren',
		edit: 'Bewerken',
		stopEdit: 'Bewerken stoppen',
		delete: 'Gebruiker verwijderen',
		resetPasswordInfo:
			"Door op de knop 'Wachtwoord opnieuw instellen' te klikken, wordt een e-mail met resetinstructies verzonden naar het e-mailadres dat aan de gebruiker is gekoppeld.",
		deleteUser: {
			confirmDeletion:
				'Weet u zeker dat u de gebruiker wilt verwijderen?',
			helperText: 'Deze actie kan NIET ongedaan worden gemaakt',
			abort: 'Annuleren',
			delete: 'Verwijderen',
		},
	},
	userPanel: {
		title: 'Gebruikers bewerken',
		helperText:
			'Klik op een rij om details te zien over een specifieke gebruiker',
		name: 'Naam',
		email: 'E-mail',
		role: 'Rol',
		editDelete: 'Bewerken/Verwijderen',
		newUser: 'Nieuwe gebruiker aanmaken',
		search: 'Zoek gebruiker',
		searchRole: 'Rol',
		noRole: 'Alle',
	},
	info: {
		newPortalTitle: 'We hebben een nieuw portaal!',
		newPortal:
			'Als u problemen ondervindt, neem dan contact op met support@weknowit.se',
	},
	workHistory: {
		title: 'Werkgeschiedenis',
		helperText:
			'Hieronder vindt u een lijst van eerder werk gedaan door de maker.',
		searchCompany: 'Zoek bedrijf',
		answerYes: 'Ja',
		answerNo: 'Nee',
		workPrepTitle: 'Werkvoorbereidingen',
		fireAlarmTitle: 'Brandalarm uitschakelingen en Heet werk',
		serviceWorkTitle: 'Service works',
		creatorHistoryTitle: 'Geschiedenis van de maker',
	},
	workHistoryByCompany: {
		title: 'Werkgeschiedenis',
		helperText:
			'Hieronder vindt u een lijst van eerder werk gedaan door de maker.',
		answerYes: 'Ja',
		answerNo: 'Nee',
		workPrepTitle: 'Werkvoorbereidingen',
		fireAlarmTitle: 'Brandalarm uitschakelingen en Heet werk',
		serviceWorkTitle: 'Service works',
		creatorHistoryTitle: 'Geschiedenis van de maker',
	},
	tooltips: {
		workPrep: {
			workSteps_NL:
				'Geef kort de uit te voeren werkstappen weer, met de focus op de taken die deel uitmaken van het werk, zoals: <br/><br/><b>Voorbeelden:</b><br/><br/><b>Vervangen van verlichting in de winkelgang: </b>Installeren van nieuwe lampen en armaturen aan het plafond.<br/><b>Installeren van ventilatie in technische ruimtes: </b>Plaatsen van leidingen en ventilatoren voor een betere luchtcirculatie.<br/><b>Sloop en renovatie van winkelruimtes: </b>Verwijderen van oude muren, plaatsen van nieuwe scheidingswanden en voorbereiden van vloeren.<br/><b>Schilderen en oppervlakte-renovatie in restaurants: </b>Egaliseren en schilderen van muren en vervangen van vloermaterialen.<br/><b>Installeren van borden op de gevel: </b>Bevestigen van nieuwe borden en reclamemateriaal buiten.<br/><b>Onderhoudswerkzaamheden in de garage: </b>Repareren van verlichting en ventilatiesystemen.<br/><b>Onderhoud van brandbeveiligingssystemen: </b>Testen en vervangen van sprinklers en rookmelders.',
			workArea_NL:
				'Geef aan in welk gebied het werk zal worden uitgevoerd, zoals: <br/><br/><b>Voorbeelden:</b><br/><br/><b>Winkel: </b>Binnen een winkel, inclusief opslagruimtes.<br/><b>Winkelgang: </b>Gemeenschappelijke ruimtes tussen winkels die door klanten worden gebruikt.<br/><b>Technische ruimte: </b>Technische kamers voor elektrische en HVAC-systemen.<br/><b>Garage: </b>Parkeerfaciliteiten, inclusief opritten en ingangen.<br/><b>Servicegang: </b>Toegangspassages voor personeel en leveringen.<br/><b>Restaurant: </b>Eetruimtes, keukens.<br/><b>Buitengevel: </b>Buitenmuren en entreegebieden.<br/><b>Plein/Ingang: </b>Open ruimtes en hoofdingangen van het winkelcentrum.',
			numPeople_NL:
				'Geef het totale aantal personen aan dat betrokken zal zijn bij het werk, inclusief werknemers, supervisors en eventuele onderaannemers.',
			floor_NL:
				'Geef aan op welke verdieping het werk zal worden uitgevoerd in het winkelcentrum. Voeg indien nodig extra details toe, zoals nabijheid van ingangen of specifieke gebieden. <br/><br/><b>Voorbeelden:</b><br/><br/><b>Begane grond: </b>In de buurt van hoofdingangen of winkels.<br/><b>Eerste verdieping: </b>In het restaurantgedeelte of extra winkelruimtes.<br/><b>Kelder: </b>In garages of technische ruimtes.<br/><b>Dak: </b>Voor ventilatie- of bordeninstallaties.',
			storeIdOrName_NL:
				'Voer de winkelnaam in. Deze informatie helpt om de exacte locatie te identificeren voor planning en coördinatie. <br/><br/><b>Voorbeelden:</b><br/><br/><b>Winkel-ID: </b>Berkenhove 4<br/><b>Naam: </b>Samsung.',
			insuranceCompanyNameQuestion_NL:
				'Voer de naam in van de CAR-verzekeringsmaatschappij die het uitgevoerde werk dekt. Zorg ervoor dat je de volledige naam opgeeft voor nauwkeurige documentatie. <br/><br/><b>Voorbeelden:</b><br/><br/>ABC Verzekeringen B.V.',
			insuranceContractNumberQuestion_NL:
				'Voer het contractnummer in dat is verstrekt door de verzekeringsmaatschappij voor de CAR-verzekeringspolis. Dit nummer wordt gebruikt om de dekking te verifiëren en claims te vergemakkelijken indien nodig.',
			liabilityInsurance:
				'Voer de naam in van de aansprakelijkheidsverzekeringsmaatschappij die potentiële schade of ongevallen met betrekking tot het werk dekt. Zorg ervoor dat je de volledige naam opgeeft voor nauwkeurige documentatie. <br/><br/><b>Voorbeelden:</b><br/><br/>Secure Liability Insurance Ltd.',
			liabilityInsuranceContractNumber:
				'Voer het contractnummer in dat is verstrekt door de verzekeringsmaatschappij voor de aansprakelijkheidsverzekeringspolis. Dit nummer wordt gebruikt om de dekking te verifiëren en claims te vergemakkelijken indien nodig.',
			clientName_NL:
				'Voer het e-mailadres in van de persoon die de opdracht heeft gegeven. <br/><br/><b>Voorbeeld:</b><br/><br/>john.doe@example.com',
			clientTelephone_NL:
				'Voer het telefoonnummer in van de persoon die de opdracht heeft gegeven. Voeg de landcode toe indien van toepassing.',
			clientEmail_NL:
				'Voer het e-mailadres in van de persoon die de opdracht heeft gegeven. <br/><br/><b>Voorbeeld:</b><br/><br/>john.doe@example.com',
			projectManagerExistsQuestion_NL:
				'Voer de naam, het telefoonnummer en het e-mailadres in van de tenantcoördinator of projectmanager bij URW, indien beschikbaar. Zorg ervoor dat de informatie correct is voor een effectieve communicatie. <br/><br/><b>Voorbeelden:</b><br/><br/><b>Naam: </b>John Doe<br/><b>Telefoon: </b>+31 70 123 45 67<br/><b>E-mail: </b>john.doe@urw.com',
			measureForWorkEnvironmentRisksComment_NL:
				'<b>Voorbeelden:</b><br/><br/><b>Valgevaar: </b>Gebruik van veiligheidsharnas en leuningen.<br/><b>Gevaarlijke stoffen: </b>Gebruik van beschermende handschoenen en maskers.<br/><b>Zwaar tillen: </b>Gebruik van hefapparatuur en juiste technieken.<br/><b>Passerend verkeer: </b>Gebruik van barrières en waarschuwingsborden.<br/><b>Stof: </b>Gebruik van masker en bril.<br/><b>Trillingen / lawaai: </b>Gebruik van trillingsdempende handschoenen en gehoorbescherming.<br/><b>Brandgevaar: </b>Gebruik van brandblussers en brandveilige materialen.',
			describeWorkSteps_NL:
				'Beschrijf de werkstappen, rekening houdend met de methode, materialen, apparatuur en benodigde arbeidskrachten. Probeer specifiek te zijn om een duidelijk begrip en een effectieve planning te waarborgen. <br/><br/><b>Voorbeeld:</b><br/><br/><b>Methode: </b>Boren van gaten in betonnen muren.<br/><b>Materiaal: </b>Betonboorkoppen en pluggen.<br/><b>Apparatuur: </b>Boormachine en stofafzuiger.<br/><b>Arbeidskracht: </b>2 gecertificeerde arbeiders.',
		},
		fireAlarmShutdown: {
			workArea_NL:
				'Geef aan welke gebieden, bedrijven of ruimtes getroffen zullen worden door de afsluiting. Wees duidelijk over welke delen van het winkelcentrum of winkels beïnvloed zullen worden om te helpen met planning en communicatie. <br/><br/><b>Voorbeelden:</b><br/><br/><b>Gebied: </b>Gang op de eerste verdieping nabij elektronica winkels.<br/><b>Bedrijf: </b>Fashion Hub en Gourmet Grill.<br/><b>Ruimte: </b>Opslagruimte en technische ruimte in de kelder.',
			shutdownReason_NL:
				'Geef duidelijk en beknopt de reden voor de afsluiting aan. Beschrijf waarom de afsluiting nodig is om te helpen met planning en communicatie. <br/><br/><b>Voorbeelden:</b><br/><br/><b>Reden: </b>Elektrisch onderhoud en veiligheidsinspecties.<br/><b>Reden: </b>Installatie van een nieuw ventilatiesysteem.<br/><b>Reden: </b>Testen van het brandalarm en systeemupdates.',
		},
		serviceWork: {
			workSteps_NL:
				'Geef kort de uit te voeren werkstappen weer, met de focus op de taken die deel uitmaken van het werk, zoals: <br/><br/><b>Voorbeelden:</b><br/><br/><b>Vervangen van verlichting in de winkelgang: </b>Installeren van nieuwe lampen en armaturen aan het plafond.<br/><b>Installeren van ventilatie in technische ruimtes: </b>Plaatsen van leidingen en ventilatoren voor een betere luchtcirculatie.<br/><b>Sloop en renovatie van winkelruimtes: </b>Verwijderen van oude muren, plaatsen van nieuwe scheidingswanden en voorbereiden van vloeren.<br/><b>Schilderen en oppervlakte-renovatie in restaurants: </b>Egaliseren en schilderen van muren en vervangen van vloermaterialen.<br/><b>Installeren van borden op de gevel: </b>Bevestigen van nieuwe borden en reclamemateriaal buiten.<br/><b>Onderhoudswerkzaamheden in de garage: </b>Repareren van verlichting en ventilatiesystemen.<br/><b>Onderhoud van brandbeveiligingssystemen: </b>Testen en vervangen van sprinklers en rookmelders.',
			workArea_NL:
				'Geef aan in welk gebied het werk zal worden uitgevoerd, zoals: <br/><br/><b>Voorbeelden:</b><br/><br/><b>Winkel: </b>Binnen een winkel, inclusief opslagruimtes en presentatiesecties.<br/><b>Winkelgang: </b>Gemeenschappelijke ruimtes tussen winkels die door klanten worden gebruikt.<br/><b>Technische ruimte: </b>Technische kamers voor elektrische en HVAC-systemen.<br/><b>Garage: </b>Parkeerfaciliteiten, inclusief opritten en ingangen.<br/><b>Servicegang: </b>Toegangspassages voor personeel en leveringen.<br/><b>Restaurant: </b>Eetruimtes, keukens en serveerzones.<br/><b>Buitengevel: </b>Buitenmuren en entreegebieden.<br/><b>Plein/Ingang: </b>Open ruimtes en hoofdingangen van het winkelcentrum.',
			numPeople_NL:
				'Geef het totale aantal personen aan dat betrokken zal zijn bij het werk, inclusief werknemers, supervisors en eventuele onderaannemers.',
			floor_NL:
				'Geef aan op welke verdieping het werk zal worden uitgevoerd in het winkelcentrum. Voeg indien nodig extra details toe, zoals nabijheid van ingangen of specifieke gebieden. <br/><br/><b>Voorbeelden:</b><br/><br/><b>Begane grond: </b>In de buurt van hoofdingangen of winkels.<br/><b>Eerste verdieping: </b>In het restaurantgedeelte of extra winkelruimtes.<br/><b>Kelder: </b>In garages of technische ruimtes.<br/><b>Dak: </b>Voor ventilatie- of bordeninstallaties.',
			storeIdOrName_NL:
				'Voer het winkel-ID in als je het nummer hebt; anders is de winkelnaam ook toegestaan. Deze informatie helpt om de exacte locatie te identificeren voor planning en coördinatie. <br/><br/><b>Voorbeelden:</b><br/><br/><b>Winkel-ID: </b>1023<br/><b>Naam: </b>Gourmet Grill.',
		},
	},
}
