import {
	FormControlLabel,
	Grid,
	Switch,
	SxProps,
	Typography,
	Tooltip,
} from '@mui/material'
import { useField } from 'formik'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

interface Props {
	label: string
	name: string
	comment?: JSX.Element | JSX.Element[]
	sx?: SxProps
	tooltip?: React.ReactNode | string
}

const CustomSwitchInput = ({
	label,
	name,
	comment,
	sx,
	tooltip = '',
}: Props) => {
	const { t } = useTranslation()

	const [field] = useField({ name, type: 'checkbox' })

	return (
		<Box sx={{ ...sx, display: 'flex', flexDirection: 'column' }}>
			<Grid container justifyContent="space-between" alignItems="center">
				<Grid
					item
					sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
				>
					<Typography fontWeight={500}>{label}</Typography>
					{tooltip && (
						<Tooltip
							title={tooltip}
							arrow
							componentsProps={{
								tooltip: {
									sx: {
										bgcolor: 'white',
										color: 'rgba(0, 0, 0, 0.87)',
										boxShadow:
											'0 2px 10px rgba(0, 0, 0, 0.2)',
										border: '1px solid rgba(0, 0, 0, 0.1)',
										maxWidth: 500,
										padding: '10px',
										fontWeight: 'normal',
									},
								},
								arrow: {
									sx: {
										color: 'white',
									},
								},
							}}
						>
							<HelpOutlineIcon color="action" fontSize="small" />
						</Tooltip>
					)}
				</Grid>
				<Grid item>
					<FormControlLabel
						control={<Switch {...field} checked={field.value} />}
						label={
							<Typography fontWeight={300}>
								{field.value ? t('common.yes') : t('common.no')}
							</Typography>
						}
					/>
				</Grid>
			</Grid>

			{comment && <Box>{comment}</Box>}
		</Box>
	)
}

export default CustomSwitchInput
